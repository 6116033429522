.side-menu{
  padding: 49px 0 30px;
  background-color: $white;
  @media #{$small_mobile}{
    padding: 30px 0 20px;
    margin-bottom: 30px;
  }
  @media #{$large_mobile}{
    margin-bottom: 30px;
  }
  span{
    color: $white;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 13px;
    display: inline-block;
    @media #{$tab}{
      margin-bottom: 5px;
    }
  }
  h4{
    font-size: 14px;
    text-transform: uppercase;
    color: $font_2;
    padding-left: 40px;
    margin-bottom: 20px;
    @media #{$small_mobile}{
      padding-left: 20px;
      margin-bottom: 10px;
    }
  }
  .product_logo{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin: 0 auto;
    text-align: center;
    flex-wrap: wrap;
    @media #{$tab}{
      display: none;
    }
    @media #{$medium_device}{
      display: none;
    }
    .single_product_logo{
      border: 1px solid #343739;
      padding: 15px 25px;
      flex: 0 0 12%;
      @include transform_time(.5s);
      img{
        filter: brightness(.5);
        @include transform_time(.5s);
      }
      &:hover{
        background-color: #343739;
        img{
          filter: brightness(1);
        }
      }
    }
  }
  .nav{
    background-color: transparent;
  }
  .dropdown, .dropleft, .dropright, .dropup {
    position: inherit;
    @media #{$tab} {
      position: relative;
    }
  }
}
.side-menu .head .icon {
  margin-right: 20px;
  @media #{$small_mobile}{
    margin-right: 10px;
  }
}
.side-menu nav .nav > li {
  width: 100%;
  display: block;
}
.side-menu nav .nav > li > a {
  padding: 12px 40px;
  color: $font_2;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  font-weight: 300;
  background-color: $white; 

  @media #{$small_mobile}{
    padding: 11px 20px;
  }
  i{
    color: $p_color;
  }
}
.side-menu nav .nav .show > a {
    background-color: $section_bg;
    i{
      color: $base_color
    }
}
.side-menu nav .nav > li > a i {
  font-size: 15px;
  margin-right: 25px;
  
}
.side-menu nav .nav > li > a:hover .icon,
.side-menu nav .nav > li > a:focus .icon {
  color: #666666;
}
.side-menu nav .nav > li > .mega-menu {
  padding:  0;
  left: 0 !important;
  margin: 0;
  width: 100%;
  position: absolute;
  top: 0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  transform: translate3d(0px, 0px, 0px) !important;
  @media #{$big_device}{
    left: 100% !important;
    top: 0 !important;
    min-width: 296%;
  }
  @media #{$medium_device}{
    left: 100% !important;
    top: 0 !important;
    min-width: 196%;
    transform: translate3d(0px, 0px, 0px) !important;
  }
  @media #{$tab}{
    top: 40px !important;
    transform: translate3d(0px, 0px, 0px) !important;
  }
}
.single_page_menu_item{
  .side-menu nav .nav > li > .mega-menu {
    @media #{$big_device}{
      left: 100% !important;
      top: 0 !important;
      min-width: 309%;
      transform: translate3d(0px, 0px, 0px) !important;
    }
  }
}
.side-menu nav .nav > li > .mega-menu .spn-content {
  padding: 34px 50px;
  background-color: $font_2;
  margin-left: -15px;
  margin-top: -1px;
  @media #{$tab}{
    margin-left: 0;
    padding: 20px 25px;
  }
}
.side-menu nav .nav > li > .mega-menu .spn-content ul > li {
  padding: 3px 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.side-menu nav .nav > li > .mega-menu .spn-content ul > li:last-child {
  border-bottom: none;
}
.side-menu nav .nav > li > .mega-menu .spn-content ul > li > a {
  line-height: 16px;
  padding: 0px;
  color: #8f8f8f;
  font-size: 13px;
  @include transform_time(.5s);
  &:hover{
    color: $base_color;
  }
}
.side-menu nav .nav > li > .mega-menu .spn-content .dropdown-banner-holder {
  position: absolute;
  right: -36px;
  top: -8px;
}
.side-menu2 nav .nav li a {
  padding: 14.3px 15px;
}
.sidebar-module-container .sidebar-widget .widget-header {
  background: #eeeeee;
  padding: 10px 15px;
}
.sidebar-module-container .sidebar-widget .widget-header .widget-title {
  font-size: 14px;
  margin: 0px;
}
.sidebar-module-container .sidebar-widget .sidebar-widget-body .accordion .accordion-group .accordion-heading .accordion-toggle {
  clear: both;
  display: block;
  font-size: 13px;
  font-weight: 300;
  line-height: 36px;
}
.sidebar-module-container .sidebar-widget .sidebar-widget-body .accordion .accordion-group .accordion-heading .accordion-toggle:after {
  content: "\f147";
  float: right;
  font-family: fontawesome;
}
.sidebar-module-container .sidebar-widget .sidebar-widget-body .accordion .accordion-group .accordion-heading .accordion-toggle.collapsed {
  color: #666666;
}
.sidebar-module-container .sidebar-widget .sidebar-widget-body .accordion .accordion-group .accordion-heading .accordion-toggle.collapsed:after {
  color: #636363;
  content: "\f196";
  font-family: fontawesome;
}
.sidebar-module-container .sidebar-widget .sidebar-widget-body .accordion .accordion-group .accordion-body .accordion-inner {
  margin: 14px 0 20px;
}
.sidebar-module-container .sidebar-widget .sidebar-widget-body .accordion .accordion-group .accordion-body .accordion-inner ul {
  padding-left: 15px;
}
.sidebar-module-container .sidebar-widget .sidebar-widget-body .accordion .accordion-group .accordion-body .accordion-inner ul li {
  line-height: 27px;
}
.sidebar-module-container .sidebar-widget .sidebar-widget-body .accordion .accordion-group .accordion-body .accordion-inner ul li a {
  color: #666666;
}
.sidebar-module-container .sidebar-widget .sidebar-widget-body .accordion .accordion-group .accordion-body .accordion-inner ul li a:before {
  content: "\f105";
  font-family: fontawesome;
  font-size: 14px;
  line-height: 15px;
  margin: 0 5px 0 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.sidebar-module-container .sidebar-widget .sidebar-widget-body .accordion .accordion-group .accordion-body .accordion-inner ul li a:hover:before {
  margin: 0 8px 0 0;
}
.sidebar-module-container .sidebar-widget .sidebar-widget-body .price-range-holder {
  padding: 0 0 20px;
  position: relative;
}
.sidebar-module-container .sidebar-widget .sidebar-widget-body .price-range-holder .slider {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin-top: 15px;
}
.sidebar-module-container .sidebar-widget .sidebar-widget-body .price-range-holder .slider.slider-horizontal {
  height: 20px;
  width: 100% !important;
}
.dropdown-toggle::after {
  display: none;
}
.links{
  padding: 16px 0;
  @media #{$tab}{
    padding: 8px 0;
  }
}
.mega_menu_product{
  @media #{$tab}{
    display: none;
  }
  @media #{$medium_device}{
    display: none;
  }
}
.single_mega_menu_product{
  margin-bottom: 15px;
  border: 4px solid #343739;
  display: inherit;
  .media{
    background-color: $font_2;
    height: 75px;
    .media_img{
      width: 75px;
      text-align: center;
    }
    .media-body{
      background-color: #343739;
      height: 100%;
      align-items: center;
      display: flex;
      padding-left: 25px;
    }
  }
}

.show{
  .dropdown-toggle{
    position: relative;
    z-index: 99;
    &::before{
      position: absolute;
      content: "";
      right: -2px;
      top: 10px;
      -webkit-clip-path: polygon(100% 7%, 100% 84%, 0 43%);    
      clip-path: polygon(100% 7%, 100% 84%, 0 43%);
      background-color: $font_2;
      width: 8px;
      height: 20px;
      @media #{$tab}{
        right: 4px;
        top: 12px;
        transform: rotate(-90deg);
      }
    }
  }
}
.menu_product{
  display: inherit;
}
.dropdown-menu .mega-menu{
  opacity: 0;
  @include transform_time(1s);
}
.main_menu{
  .single_page_menu{
    position: absolute;
    z-index: 99;
    display: none;
    top: 90px;
    background-color: $font_2;
    @media #{$tab}{
      margin-right: 15px;
    }
    .nav > li > a {
      color: $p_color;
      background-color: $font_2;
    }
    h4{
      color: $white;
    }
    nav .nav > li > .mega-menu .spn-content{
      background-color: #242729;
    }
    nav .nav .show > a {
      background-color: #2b2d2e;
      i{
        color: $base_color
      }
    }
    .show .dropdown-toggle::before {
      position: absolute;
      content: "";
      right: 13px;
      top: 10px;
      -webkit-clip-path: polygon(100% 7%, 100% 84%, 0 43%);    
      clip-path: polygon(100% 7%, 100% 84%, 0 43%);
      background-color: #242729;
      width: 8px;
      height: 20px;
      @media #{$tab}{
        background-color: $base_color;
      }
  }
  }
  .active{
    display: block;
    box-shadow: 1px 14px 23px -14px rgba(0, 0, 0, .5);
  }
  

}


@media #{$medium_device}{
  .single_page_menu_item .side-menu nav .nav > li > .mega-menu {
    left: 100% !important;
    top: 0 !important;
    min-width: 207%;
    transform: translate3d(0px, 0px, 0px) !important;
  }
}

@media #{$big_device}{
  .show{
    &:hover{
      .dropdown-menu{
        display: block;
      }
    }
  }
}
@media #{$big_device}{
  .single_page_menu_item{
    .show{
      .dropdown-menu{
        display: block;
      }
    }
  }
}
