/************* blog part css here ****************/
.blog_part{
    .single_blog_post{
        margin-bottom: 40px;
        @media #{$tab}{
            display: block !important;
        }
        &:last-child{
            margin-bottom: 0;
        }
        .single_blog_post_img{
            img{
                width: 100%;
            }
            @media #{$tab}{
                margin-bottom: 25px;
            }
        }
        .single_blog_post_content{
            padding-left: 30px;
            @media #{$tab}{
                padding: 0;
            }
            h4{
                font-size: 24px;
                font-weight: 500;
                a{
                    color: $font_2;
                    @include transform_time(.5s);
                    &:hover{
                        color: $base_color;
                    }
                }
            }
            p{
                font-weight: 400;
                margin: 24px 0 40px;
                @media #{$tab}{
                    margin: 15px 0 20px;
                }
                @media #{$medium_device}{
                    margin: 15px 0 20px;
                }
            }
        }
    }
}
.blog_post_details{
    a{
        color: $p_color;
        font-size: 13px;
        font-weight: 400;
        margin-right: 10px;
        padding-right: 10px;
        border-right: 1px solid $p_color;
        line-height: 15px;
        @media #{$tab}{
            margin-right: 8px;
            padding-right: 8px;
        }
        &:last-child{
            border-right: 0;
        }
        i{
            margin-right: 5px;
        }
    }
}