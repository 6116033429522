/************** blog sidebar css here **************/
.blog_sidebar{
    background-color: $font_2;
    padding: 80px 50px;
    @media #{$tab}{
        padding: 30px 15px;
        margin-top: 70px;
    }
    @media #{$medium_device}{
        padding: 30px 15px;
    }
    .input-group-append{
        width: 100%;
    }
    .sidebar_tittle{
        margin-bottom: 30px;
        @media #{$tab}{
            margin-bottom: 15px;
        }
        h5{
            font-size: 13px;
            color: $white;
            border-bottom: 1px solid #343739;
            padding-bottom: 15px;
            margin-bottom: 0;
        }
    }
    .single_blog_sidebar{
        margin-top: 60px;
        @media #{$tab}{
            margin-top: 30px;
        }
        @media #{$medium_device}{
            margin-top: 40px;
        }
        &:first-child{
            margin-top: 0;
        }
        form{
            input{
                border-radius: 0;
                color: #8f8f8f;
                background-color: #2b2d2e;
                border: 0px;
                font-size: 13px;
                height: 40px;
                padding-left: 20px;
            }
            .input-group-text{
                border-radius: 0;
                color: $white;
                background-color: #2b2d2e;
                border: 0px;
                font-size: 13px;
                height: 40px;
                margin-left: -1px;
            }
        }
        .single_sidebar_post{
            margin-bottom: 30px;
            @media #{$medium_device}{
                margin-bottom: 20px;
            }
            &:last-child{
                margin-bottom: 0;
            }
            .single_post_img{
                img{
                    max-width: 75px;
                }
            }
            .single_post_content{
                padding-left: 20px;
                h5{
                    font-size: 14px;
                    color: $white;
                    line-height: 21px;
                    a{
                        color: $white;
                        @include transform_time(.5s);
                        &:hover{
                            color: $base_color;
                        }
                    }
                }
            }
        }
        ul{
            padding: 0;
            margin: 0;
            li{
                list-style: none;
                margin-bottom: 20px;
                &:last-child{
                    margin-bottom: 0;
                }
                a{
                    color: $p_color;
                    &:hover{
                        color: $base_color;
                    }
                }
            }
        }
        .instagram_img{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .single_instagram_img{
                flex: 31% 0 0;
                margin-bottom: 10px;
                img{
                    width: 100%;
                }
            }
        }
        .tag_list{
            a{
                font-size: 11px;
                background-color: #2b2d2e;
                color: $p_color;
                padding: 10px 13px;
                display: inline-block;
                margin: 0 1px 5px 0;
                @include transform_time(.5s);
                &:hover{
                    background-color: $base_color;
                    color: $white;
                }
            }
        }
    }
}