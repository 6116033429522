/********** category part scss***********/
.category_part{
    background-color: $white;
    padding: 120px 0 0;
    @media #{$tab}{
        padding: 70px 0 0;
    }
    @media #{$medium_device}{
        padding: 80px 0 0;
    }
    .category_sidebar{
        background-color: $font_2;
        padding: 80px 50px;
        @media #{$tab}{
            padding: 40px 25px;
        }
        @media #{$medium_device}{
            padding: 40px 25px;
        }
        .category_refress {
            background-color: #2b2d2e;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;
            color: #444749;
            a{
                color: $p_color;
            }
        }
        .single_category{
            margin-top: 58px;
            @media #{$tab}{
                margin-top: 30px;
            }
            @media #{$medium_device}{
                margin-top: 30px;
            }
            .category_tittle {
                h4{
                    font-size: 16px;
                    color: $white;
                    border-bottom: 1px solid #343739;
                    padding-bottom: 13px;
                    margin-bottom: 30px;
                    @media #{$tab}{
                        margin-bottom: 15px;
                    }
                    @media #{$medium_device}{
                        margin-bottom: 15px;
                    }
                }
            }
            .single_category_option{
                ul{
                   padding: 0;
                   margin: 0; 
                   li{
                    margin: 24px 0;
                    
                    @media #{$tab}{
                        margin: 15px 0;
                    }
                    @media #{$medium_device}{
                        margin: 15px 0;
                    }
                       a{
                           color: $p_color;
                           display: flex;
                           justify-content: space-between;
                       }
                       ul{
                        padding-left: 10px;
                        li{
                            display: flex;
                           justify-content: space-between;
                           &:last-child{
                                margin: 24px 0 0;
                                @media #{$tab}{
                                    margin: 15px 0 0;
                                }
                            }
                        }
                     }
                   }
                }
                .sub-menu{
                    margin: 11px 0;
                    display: inline-block;
                    width: 100%;
                    &:last-child{
                        margin: 11px 0 0;
                    }
                }
            }
        }
    }
 
    .extra-controls{
        display: inline-block;
        .price_rangs{
            width: 50%;
            float: left;
            display: flex;
            align-items: center;
            p{
                padding-left: 10px;
            }
        }
        .form-control{
            width: 50%;
            background-color: #2b2d2e;
            border-radius: 0;
            border: 1px solid #2b2d2e;
        }
    }
    .category_product_page{
        .product_page_tittle{
            align-items: center;
            margin-bottom: 20px;
            @media #{$tab}{
                display: inline-block !important;
                padding: 20px 0;
            }
        }
        .short_by{
            @media #{$tab}{
                margin-top: 15px;
            }
            .nice-select{
                width: 100%;
                height: 40px;
                width: 260px;
                border-radius: 0;
                color: $black;
                line-height: 40px;
                &:after{
                    top: 45%;
                }
                .option{
                    font-size: 13px;
                    text-transform: capitalize;
                }
                
            }
            .list{
                width: 265px;
                border-radius: 0;
                z-index: 99;
            }
        }
    }
    .category_product_page{
        .product_tricker{
            background-color: $section_bg;
            padding: 15px;
        }
        .product_text{
            padding: 17px;
            h5{
                a{
                    font-size: 15px;
                    line-height: 24px
                }
            }
        }
        .product_img{
            background-color: $white;
            min-height: 250px;
            img{
                max-width: 200px;
                max-height: 145px;
            }
            @media #{$tab}{
                min-height: 200px;
                img{
                    max-width: 150px;
                    max-height: 100px;
                }
            }
            @media #{$medium_device}{
                max-height: 165px;
                min-height: 165px;
                img{
                    max-width: 150px;
                    max-height: 100px;
                }
            }
        }
        .pagination_part{
            margin-top: 20px;
        }
    }  
}
.radio {
    margin: 3px 10px;
    display: inline-block;
    input[type="radio"] {
      position: absolute;
      opacity: 0;
      + .radio-label {
        &:before {
          content: '';
          border-radius: 100%;
          display: inline-block;
          width: 30px;
          height: 30px;
          position: relative;
          top: -8px;
          margin-right: 1em; 
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }
      &:checked {
        + .radio-label {
          &:before {
            box-shadow: inset 0 0 0 4px $color1;
          }
        }
      }
      &:focus {
        + .radio-label {
          &:before {
            outline: none;
          }
        }
      }
      &:disabled {
        + .radio-label {
          &:before {
            box-shadow: inset 0 0 0 4px $color1;
            border-color: darken($color1, 25%);
            background: darken($color1, 25%);
          }
        }
      }
      + .radio-label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }
.color_1{
    .radio-label{
        &:before{
            background-color: #8ed0dc;
            border: 1px solid #8ed0dc;
        }
    }
}
.color_2{
    .radio-label{
        &:before{
            background-color: #f49660;
            border: 1px solid #f49660;
        }
    }
}
.color_3{
    .radio-label{
        &:before{
            background-color: #8ed0dc;
            border: 1px solid #8ed0dc;
        }
    }
}
.color_4{
    .radio-label{
        &:before{
            background-color: #ffb586;
            border: 1px solid #ffb586;
        }
    }
}
.color_5{
    .radio-label{
        &:before{
            background-color: #f7bd59;
            border: 1px solid #f7bd59;
        }
    }
}
.color_6{
    .radio-label{
        &:before{
            background-color: #8377db;
            border: 1px solid #8377db;
        }
    }
}
.color_7{
    .radio-label{
        &:before{
            background-color: #ff81ca;
            border: 1px solid #ff81ca;
        }
    }
}
.color_8{
    .radio-label{
        &:before{
            background-color: #80ceff;
            border: 1px solid #80ceff;
        }
    }
}
.materials_content{
    margin-top: 34px !important;
    @media #{$tab}{
        margin-top: 13px !important;
    }
    ul li{
        display: flex;
        justify-content: space-between;
    }
}
.Wholesale{
    .pagination_part{
        margin-top: 20px;
    }
}
