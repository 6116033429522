/********* marcent css *********/
.marcent_content{
    text-align: center;
   h5{
       font-size: 16px;
       text-transform: uppercase;
       margin-bottom: 26px;
   }
   span{
       display: block;
       margin-top: 20px;
   }
   .btn_1{
    margin-top: 42px;
   }
}
.benefits_content{
    .single_benefits_content{
        padding-right: 117px;
        
        @media #{$tab}{
            padding-right: 0;
            margin-bottom: 30px;
        }
        @media #{$medium_device}{
            padding-right: 0;
            margin-bottom: 30px;
        }
        h4{
            font-size: 18px;
            line-height: 27px;
            padding: 19px 0 23px;
            @media #{$tab}{
                padding: 10px 0 0;
            }
        }
    }
}