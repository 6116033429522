/******* checkout form scss ********/
.checkout_form{
    background-color: $white;
    ::placeholder{
        font-size: 14px;
        text-transform: capitalize;
        color: $p_color;
    }
    .billing_address{
        h4{
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 38px;
        }
        .form-control{
            border-radius: 0;
            height: 50px;
            margin-bottom: 17px;
            color: $p_color;
            font-weight: 300;
        }
        label, p{
           font-size: 11px; 
           text-transform: uppercase;
           color: $font_2;
           font-weight: 300;
        }
        .select_address{
            border-radius: 0;
            width: 100%;
            span{
                font-size: 14px;
                font-weight: 300;
                color: $p_color;
            }
        }
        // .nice-select:after{
        //     content: "\e61a";
        //     font-family: 'themify';
        //     border-bottom: 0px solid transparent;
        //     border-right: 0px solid transparent;
        //     font-size: 16px;
        //     top: 4px;
        //     transform: rotate(0deg);
        //     right: 19px;
        // }
        // .nice-select.open:after{
        //     content: "\e622";
        //     font-family: 'themify';
        //     border-bottom: 0px solid transparent;
        //     border-right: 0px solid transparent;
        //     font-size: 16px;
        //     top: 4px;
        //     transform: rotate(0deg);
        //     right: 19px;
        // }
        .select_address{
            margin-bottom: 15px;
        }
        .product_ceck{
            margin-top: 34px;
            ul{
                list-style: none;
                margin: 0;
                padding: 0;
                li{
                    display: flex;
                    margin-bottom: 25px;
                    a{
                        color: $p_color;
                        margin-left: 15px;
                    }
                }
            }
            .cs_checkbox .checkmark {
                position: absolute;
                top: -3px;
                left: 0;
                height: 25px;
                width: 25px;
                background-color: #f4f7f9;
                border: 1px solid #e2e6eb !important;
            }
            .cs_checkbox input:checked ~ .checkmark {
                background-color: $font_2 !important;
                border: 1px solid $font_2 !important;
            }
        }
    }
    .text_area{
        margin-top: 33px;
        border-top: 1px dashed;
        padding-top: 48px;
        h5{
            font-size: 11px;
            text-transform: uppercase;
            font-weight: 300;
            margin-bottom: 10px;
        }
        .optional_text_area {
            padding: 13px 20px;
            width: 100%;
            height: 140px;
            border: 1px solid $border_color_1;
        }
    }
    .btn_1, .btn_2{
        padding: 14.5px 35px;
        display: block;
        text-align: center;
    }
    .btn_2{
        border: 1px solid #d7dbe3;
        color: #0f2137 !important;
        margin-top: 40px;
        &:hover{
            border: 1px solid $base_color;
            background-color: $base_color;
            color: $white !important;
        }
    }
    .btn_1{
        margin-top: 10px;
        &:hover{
            border: 1px solid $base_color;
        }
    }
}
.order_information{
    padding: 40px 30px 26px;
    background-color: $section_bg;
    border: 1px solid #e2e6eb;
    .product_name{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $white;
        padding: 11px 20px;
        margin-bottom: 25px;
    }
    p{
        color: $p_color;
    }
    ul{
        list-style: none;
        margin: 0;
        padding: 0;
        border-bottom: 1px dashed;
        padding-bottom: 6px;
        margin-bottom: 22px;
        li{
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            p{
                color: $p_color !important;
                font-size: 14px !important;
                text-transform: capitalize !important;
            }
        }
    }
    .order-price h5 {
        font-size: 20px;
        font-weight: 400;
    }
    .order-price {
        display: flex;
        justify-content: space-between;
    }
}
.payment_option{
    .btn_1{
        margin-top: 43px;
    }
    .product_ceck{
        margin-top: 12px !important;
    }
    .card_check{
        margin-bottom: 26px;
        a{
            padding-left: 40px;
            color: $p_color;
            
        }
    }
}