/********** footer css **********/
.footer_part{
    background-color: $white;
    padding-bottom: 100px;
    @media #{$tab}{
        padding-bottom: 50px;
    }
    @media #{$medium_device}{
        padding-bottom: 60px;
    }
    .single_footer_content{
        h4{
            text-transform: capitalize;
            font-size: 18px;
            margin-bottom: 29px;
        }
    }
    .footer_content{
        display: flex;
        flex-wrap: wrap;
        .single_footer_content{
            flex: 0 0 33%;
            max-width: 33%;
            @media #{$small_mobile}{
                flex: 0 0 48%;
                max-width: 48%;
            }
            @media #{$tab}{
                margin-top: 25px;
            }
            h4{
                text-transform: capitalize;
                font-size: 18px;
                margin-bottom: 29px;
                @media #{$small_mobile}{
                    margin-bottom: 15px;
                }
            }
            ul{
                padding: 0;
                margin: 0;
                li{
                    list-style: none;
                    margin-bottom: 14px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    a{
                        color: $p_color;
                        @include transform_time(.5s);
                        text-transform: capitalize;
                        &:hover{
                            color: $base_color;
                        }
                    }
                }
            }
        }
    }
    .copyright_text{
        margin-top: 23px;
        a{
            color: $base_color;
        }
    }
}