/**************** common css start ****************/
@import url('https://fonts.googleapis.com/css?family=Heebo:300,400,500,700,800,900&display=swap');
body{
    font-family: $font_style_1;
    padding: 0;
    margin: 0;
    font-size: 14px;
    background-color: $section_bg;
}
@media #{$big_device}{
    .container{
        max-width: 1380px;
    }
}

.message_submit_form:focus{
    outline: none;
}
input:hover, input:focus{
    outline: none !important;
    box-shadow: 0px 0px 0px 0px transparent !important;
}
.form-control {
    border-color: $border_color_1;
}
.form-control:focus {
    box-shadow: 0 0 0 0rem transparent !important;
    border-color: $border_color_1;
}
.dropdown-menu{
    margin: 0;
    padding: 0;
}
.section_padding {
    padding: 120px 0px;
    @media #{$medium_device}{
        padding: 80px 0px;
    }
    @media #{$tab_device}{
        padding: 70px 0px;
    }
    @media #{$small_mobile}{
        padding: 70px 0px;
    }
    @media #{$large_mobile}{
        padding: 70px 0px;
    }
}
.padding_top{
    padding-top: 120px;
    @media #{$tab}{
        padding-top: 70px;
    }
    @media #{$medium_device}{
        padding-top: 80px;
    }
}
.padding_bottom{
    padding-bottom: 120px;
    @media #{$tab}{
        padding-bottom: 70px;
    }
    @media #{$medium_device}{
        padding-bottom: 80px;
    }
}

a{
    text-decoration: none;
    @include transform_time(.5s);
    &:hover{
        text-decoration: none;
    }
}


h1, h2, h3, h4, h5, h6 {
    color: $font_2;
    font-family: $font_style_1;
}
p{
    line-height: 28px;
    font-size: 14px;
    margin-bottom: 0px;
    color: $p_color;
    font-family: $font_style_1;
    font-weight: 300;
}

a:focus, .button:focus, button:focus, .btn:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
    @include transform_time(1s);
}
.p_30{
    padding: 30px;
    @media #{$tab}{
        padding: 20px;
    }
}
.p_20{
    padding: 20px;
}
.p_15{
    padding: 20px 20px 15px;
}
.mt_40{
    margin-top: 40px;
}
.section_bg{
    background-color: $section_bg;
}
.cs_checkbox {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #343739;
        border: 1px solid #444749;
      }
  }

  .section_tittle{
      text-align: center;
      h2{
        font-size: 36px;
        margin-bottom: 25px;
        line-height: 33px;
          @media #{$tab}{
            margin-bottom: 15px;
            font-size: 25px;
          } 
          @media #{$tab}{
            font-size: 30px;
          }
      }
      p{
          margin-bottom: 72px;
          @media #{$tab}{
              margin-bottom: 40px;
          }
          @media #{$medium_device}{
              margin-bottom: 50px;
          }
      }
  }
  
  /* Create a custom checkbox */
 
  
  /* On mouse-over, add a grey background color */
  .cs_checkbox:hover input ~ .checkmark {
    background-color: $base_color;
    border: 1px solid $base_color;
  }
  
  /* When the checkbox is checked, add a blue background */
  .cs_checkbox input:checked ~ .checkmark {
    background-color: $base_color;
    border: 1px solid $base_color;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .cs_checkbox:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .cs_checkbox input:checked ~ .checkmark:after {
    display: block;
    left: 8px;
    top: 4px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    content: "";
  }

  .nice-select{
    height: 50px;
    line-height: 50px;
}
.nice-select:active, .nice-select.open, .nice-select:focus {
    border: 1px solid $border_color_1;
}
.nc_select{
    border: 1px solid $border_color_1;
    border-radius: 0;
    font-size: 13px;
    color: $p_color;
    display: block;
    width: 100%;
    text-transform: capitalize;
    padding: 0 20px;
    margin-bottom: 15px;
    &:after{
        border-bottom: 0 solid transparent;
        border-right: 0 solid transparent;
        content: "\e61a";
        font-family: 'themify';
        transform: rotate(0);
        top: 5px;
    }
    .option:hover, .option.focus, .option.selected.focus {
        background-color: $font_2;
        color: $p_color;
        font-weight: 400;
    }
    .list{
        width: 100%;
        border-radius: 0;
        background-color: $font_2;
        color: $p_color;
        font-weight: 400;
        padding: 20px;
        @media #{$medium_device}{
            padding: 5px;
        }
        li{
            @include transform_time(.5s);
            &:hover{
                color: $base_color !important;
            }
            @media #{$tab_device}{
                line-height: 33px;
                padding: 0;
            }
        }
    }
    
   
}
.nc_select.open:after {
    transform: rotate(0);
    content: "\e622";
    font-family: 'themify';
}
.active_megamenu{
    display: block;
    @media #{$tab}{
        display: none;
    }
}

  /* Style the checkmark/indicator */

/**************** common css end ****************/
