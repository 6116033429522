/*********** contact us css here ***********/
.contact_info{
    text-align: center;
    h4{
        text-transform: uppercase;
        font-size: 16px;
        margin-bottom: 27px;
        @media #{$tab}{
            line-height: 25px;
        }
    }
    .contact_btn{
        border:1px solid $border_color_1;
        background-color: #f4f7f9;
        color: #222;
        display: inline-block;
        padding: 17px 46px;
        line-height: 14px;
        margin-top: 43px;
        @include transform_time(.5s);
        @media #{$tab}{
            margin-top: 20px;
        }
        &:hover{
            background-color: $base_color;
            border: 1px solid $base_color;
            color: $white;
        }
    }
}
.contact_form{
    .send_query_form{
        background-color: $section_bg;
    }
}
/*********** contact us css end ***********/