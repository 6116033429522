/************** custom refund disputes css here ***************/
.account_details{
    .nav-link{
        border-radius: 0;
        background-color: $section_bg;
        color: $font_2;
        margin-right: 10px;
        font-size: 13px;
        padding: 17px 31px;
        line-height: 14px;
        @media #{$small_mobile}{
            margin-right: 3px;
            font-size: 13px;
            padding: 10px 8px;
        }
    }
    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
        color: $white !important;
        background-color: $font_2 !important;
        border-color: $font_2 !important;
    }
}
/************** custom refund disputes css end ***************/