/************ custom account css here ****************/
.dashboard_part{
    .account_details{
        .customer_img{
            @media #{$tab}{
                margin-top: 30px;
                padding: 0 15px;
            }
        }
        form{
            background-color: $section_bg;
            padding: 80px 36px 0;
            @media #{$tab}{
                padding: 30px 15px;
            }
            &:last-child{
                padding: 80px 36px 80px;
                @media #{$tab}{
                    padding: 30px 15px;
                }
            }
            label{
                text-transform: uppercase;
                font-size: 11px;
                font-weight: 300;
                color: $font_2;
            }
            h4{
                font-size: 16px;
                font-weight: 400;
                text-transform: uppercase;
                margin-bottom: 38px;
                color: $font_2;
            }
            .form-control {
                border-color: $border_color_1;
                font-size: 13px;
                text-transform: capitalize;
                font-weight: 300;
                border-radius: 0;
                height: 50px;
                padding: 10px 20px;
                @media #{$tab_device}{
                    padding: 10px;
                }
                @media #{$medium_device}{
                    padding: 10px 6px;
                }
            }
            .gj-textbox-md{
                border: 1px solid $border_color_1;
                background-color: $white;
            }
            textarea{
                border-color: $border_color_1;
                font-size: 13px;
                text-transform: capitalize;
                font-weight: 300;
                border-radius: 0;
                display: block;
                width: 100%;
                padding: 20px;
                height: 160px;
            }
            .gj-datepicker-md [role="right-icon"] {
                position: absolute;
                right: 20px;
                top: 18px;
                font-size: 13px;
                color: $p_color;
            }
            .btn_1{
                float: right;
                margin-top: 30px;
                @media #{$tab}{
                    margin-top: 10px;
                }
            }
        }
    }
    .form-group{
        &:last-child{
            margin-bottom: 0;
        }
    }
    .crose_icon{
        height: 50px;
        width: 58px;
        line-height: 50px;
        text-align: center;
        background-color: $base_color;
        color: $white;
        display: inline-block;
    }
    .customer_img{
        img{
            max-width: 150px;
        }
        h5{
            font-size: 11px;
            text-transform: uppercase;
            color: $font_2;
            font-weight: 300;
        }
        .form-group{
            font-size: 12px;
            font-weight: 300;
            color: $p_color;
            margin-top: 20px;
        }
    }
    .nice-select{
        height: 50px;
        line-height: 30px;
    }
    .nc_select:after {
        top: 13px;
    }
    
    .d-flex{
        .nc_select{
            margin-right: 10px;
        }
    }
    @media #{$medium_device}{
        .form-control .nc_select:after {
            font-size: 10px;
            right: 4px;
        }
    }
    
}
/************ custom account css end ****************/