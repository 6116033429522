/********* shipping css here ***********/
.shipping_product{
    background-color: $white;
    .product_tricker{
        background-color: $section_bg;
        .product_img{
            background-color: $white;
        }
    }
    .product_list_tittle{
        h5{
            margin-bottom: 40px;
        }
    }
}