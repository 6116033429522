/*********** img breadcrumb css here **************/
.img_breadcrumb{
    overflow: hidden;
    position: relative;
    z-index: 1;
    &:after{
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $font_2;
        opacity: .5;
    }
    .container-fluid{
        padding: 0;
    }
    .member_info_text{
        padding-left: 30px;
    }
}