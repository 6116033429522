/******** base color css ********/
.btn_1{
    background-color: $base_color;
    border: 1px solid $base_color;
    color: $white !important;
    display: inline-block;
    padding: 16px 41px;
    text-transform: capitalize;
    line-height: 16px;
    margin-top: 32px;
    font-size: 13px;
    font-weight: 500;
    &:hover{
        background-color: $white;
        border: 1px solid $base_color;
        color: $base_color !important;
    }
}
.btn_2{
    color: $white !important;
    border: 1px solid $white;
    display: inline-block;
    padding: 15px 39px;
    text-transform: capitalize;
    font-size: 13px;
    font-weight: 500;
    &:hover{
        background-color: $white;
        border: 1px solid $white;
        color: $base_color !important;
    }
}
.product_btn{
    font-size: 12px;
    background-color: $base_color;
    display: inline-block;
    color: $white;
    padding: 2px 15px;
    text-transform: uppercase;
    border: 1px solid $base_color;
    &:hover{
        border: 1px solid $base_color;
        background-color: transparent;
        color: $base_color;
    }
}