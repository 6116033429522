/****** product details part css ******/
.product_details_part {
  .tab_content {
    background-color: $white;
    text-align: center;
    margin-bottom: 40px;
    padding: 100px;
    @media #{$tab}{
        padding: 20px;
    }
  }
  .product_count input.qty {
    padding: 14px 20px;
  }
  .product_count {
    input.qty {
        padding: 14px 20px;
    }
    .cart-qty-plus{
        position: absolute;
        right: 9px;
        top: 16px;
    } 
    .cart-qty-minus{
        position: absolute;
        left: 9px;
        top: 16px;
    }
    
}
  .tab_thumb {
      flex-wrap: wrap;
    .nav-link {
      background-color: $white;
      padding: 15px;
    }
    .nav-item {
        flex: 24% 0 0;
        @media #{$tab}{
            flex: 48% 0 0;
            margin-bottom: 15px;
        }
        @media #{$tab_device}{
            flex: 24% 0 0;
            margin-bottom: 0;
        }
        .thamb_img{
            border: 1px solid transparent;
            @include transform_time(0.5s);
            margin: 0 auto;
            text-align: center;
        }
      img {
        max-width: 114px;
        @include transform_time(0.5s);
        transform: scale(1);
        @media #{$medium_device}{
            max-width: 75px;
        }
      }
      &:hover {
        img {
            transform: scale(.8);
        }
        .thamb_img{
            border: 1px solid $base_color;
        }
      } 
    }
  }
  .single_details_content{
      position: relative;
      padding-left: 85px;
      margin: 10px 0 0;
      align-items: center;
      @media #{$tab}{
        padding-left: 0px;
      }
      h5{
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 300;
          margin-right: 35px;
          position: absolute;
          left: 0;
          @media #{$tab}{
            position: relative;
            margin-bottom: 15px;
          }
      }
      h2{
          font-size: 21px;
          font-weight: 700;
          margin-bottom: 0;
      }
      a{
          margin-bottom: 0;
      }
  }
  
   @media #{$tab}{
    .product_details_btn{
        margin-top: 15px;
        .btn_1, .btn_2{
            padding: 16px 30px;
            margin-top: 0;
        }
    }
  }
  
  .product_details {
      @media #{$tab}{
          margin-top: 50px;
      }
      p{
          color: $p_color;
      }
      li{
          span{
              color: $p_color;
          }
      }
    .product_details_btn_iner{
        border: 1px solid $border_color_1;
        background-color: $white;
        font-size: 12px;
        color: #222;
        padding: 8px 23px;
        display: inline-block;
    }
    .product_details_btn {
      .btn_2{
          background-color: $white;
          color: $font_2 !important;
          padding: 16px 39px;
          @media #{$tab}{
            padding: 16px 30px;
          }
          &:hover{
              background-color: $base_color !important;
              color: $white !important;

          }
      }
    }
    .tittle{
        display: block;
        h2 {
            font-size: 36px;
            position: relative;
            z-index: 1;
            display: inline-block;
            padding: 19px 0 0px;
            @media #{$tab}{
                font-size: 25px;
            }
            &:after {
              position: absolute;
              content: "";
              left: 0;
              bottom: 8px;
              width: 100%;
              height: 6px;
              background-color: #d7c4eb;
              z-index: -1;
            }
          }
      }
      .product_details_review{
          .review_icon{
              margin-right: 15px;
              i{
                color: #ffb400;
                font-size: 12px;
                line-height: 29px;
              }
          }
      }
      .details_product_price{
          h2{
              font-size: 21px;
              font-weight: 700;
              color: #ff0027;
              
          }
          span{
              text-decoration: red line-through;
              margin-left: 25px;
              line-height: 25px;
          }

      }
   }
   .product_details{
       ul{
           padding: 0;
           margin: 0;
           li{
               display: inline-block;
               margin: 7px 25px;
               position: relative;
               @media #{$tab}{
                margin: 7px 0;
                }
               &:after{
                    position: absolute;
                    content: "";
                    top: 4px;
                    width: 1px;
                    height: 13px;
                    background-color: #969696;
                    left: -24px;
                    @media #{$tab}{
                        display: none;
                    }
               }
               &:first-child{
                   margin: 7px 25px 7px 0px;
                   &:after{
                       width: 0;
                       @media #{$tab}{
                        display: none;
                    }
                   }
               }
           }
       }
       .size_btn{
           a{
               display: inline-block;
               border: 1px solid $border_color_1;
               padding: 6px 19px;
               text-transform: uppercase;
               color: $font_2;
               margin-bottom: 10px;
               font-weight: 300;
               background-color: $white;
           }
       }
       h4{
           font-size: 14px;
           font-weight: 300;
           text-transform: capitalize;
           margin-bottom: 0;
       }
   }
   .details_text{
       align-items: center;
   }
   .cs_color_btn{
       display: flex;
       margin-left: 40px;
       align-items: center;
       @media #{$tab}{
           margin-left: 0;
           margin-top: 20px;
       }
       @media #{$tab_device}{
           margin-left: 0;
           margin-top: 0;
           margin-left: 15px;
       }
       .cs_radio_btn{
           margin-left: 18px;
       }
       .radio {
        margin: 3px 5px;
        display: inline-block;
       }
       .radio input[type="radio"] + .radio-label:before {
            top: 4px;
        }
   }
   .nice-select:active, .nice-select.open, .nice-select:focus {
        border-color: $border_color_1;
    }
    .nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
        background-color: $white;
    }
   .select_address{
       width: 100%;
       border-radius: 0px;
       margin: 10px 0 10px;
       height: 50px;
       line-height: 50px;
       @media #{$tab}{
          margin: 0;
       }
       &:after{
           top: 42%;
           right: 17px;
       }
       .list{
           line-height: 50px;
           width: 100%;
           display: block;
           border-radius: 0;
       }
    ul{
        margin: 0;
        &:first-child{
            margin: 7px 0;
        }
        li{
            display: block;
            margin: 7px 0;
            border-bottom: 1px dashed;
            &:first-child{
                margin: 7px 0;
            }
            &:last-child{
                border-bottom: 0 dashed transparent; 
            }
        }
    }
   }
   .details_content_iner{
       width: 100%;
       display: inline-block;
   }
   .details_product_price {
        margin: 10px 0 37px;
        @media #{$tab}{
            margin: 10px 0 15px;
        }
    }
    .single_details_content{
        margin-top: 20px;
    }
    .social_icon{
        margin-top: 48px;
        @media #{$tab}{
            margin-top: 20px;
        }
        h5{
            text-transform: capitalize;
        }
        .social_icon_iner{
            a{
                width: 35px;
                height: 35px;
                text-align: center;
                line-height: 35px;
                display: inline-block;
                color: $p_color;
                background-color: $white;
                border-radius: 50px;
                margin-left: 8px;
                &:hover{
                    background-color: $base_color;
                    color: $white !important;
                }
            }
            
        }
    }
    .product_details_content {
        margin-bottom: 37px;
        @media #{$tab}{
            margin-bottom: 20px;
        }
    }
    @media #{$tab}{
        .media_style{
            &:last-child{
                margin-bottom: 0;
            }
        }
      }
}

