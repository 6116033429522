/******** breadcrumb css *********/
.breadcrumb_cs{
    padding: 47px 0;
    .breadcrumb_content{
      @media #{$small_mobile}{
        display: block !important;
      }
        h2{
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 0;
            text-transform: capitalize;
            @media #{$small_mobile}{
              margin-bottom: 20px;
            }
        }
        .troggle_btn{
            .toggle-label {
                position: relative;
                display: block;
                width: 165px;
                height: 40px;
                margin-top: 8px;
                border: 1px solid transparent;
                border-radius: 50px;
                overflow: hidden;
              }
              .toggle-label input[type=checkbox] { 
                opacity: 0;
                position: absolute;
                width: 100%;
                height: 100%;
              }
              .toggle-label input[type=checkbox]+.back {
                position: absolute;
                width: 100%;
                height: 100%;
                background: $border_color_1;
                transition: background 150ms linear;  
                border-radius: 50px;
              }
              .toggle-label input[type=checkbox]:checked+.back {
                background: $border_color_1;
                overflow: hidden;
              }
              
              .toggle-label input[type=checkbox]+.back .toggle {
                display: block;
                position: absolute;
                content: ' ';
                background: $base_color;
                width: 50%; 
                height: 100%;
                transition: margin 150ms linear;
                border: 1px solid transparent;
                
              }
              .toggle-label input[type=checkbox]:checked+.back .toggle {
                margin-left: 82px;
              }
              .toggle-label .label {
                display: block;
                position: absolute;
                width: 50%;
                color: #ddd;
                line-height: 40px;
                text-align: center;
                font-size: 13px;
                text-transform: capitalize;
              }
              .toggle-label .label.on { 
                  left: 0px;
              }
              .toggle-label .label.off { right: 0px; }
              
              .toggle-label input[type=checkbox]:checked+.back .label.on {
                color: $font_2;
              }
              .toggle-label input[type=checkbox]+.back .label.off {
                color: $font_2;
              }
              .toggle-label input[type=checkbox]:checked+.back .label.off {
                color: $white;
              }
               
        }
    }
}