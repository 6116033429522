/*************** about us part css here ***************/
.about_us{
    h2{
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 38px;
        line-height: 48px;
        @media #{$tab}{
            font-size: 30px;
            margin-bottom: 20px;
            line-height: 40px;
        }
    }
    .about_content{
        @media #{$tab}{
            margin-bottom: 20px;
        }
    }
}
.about_details{
    h2{
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 38px;
        line-height: 48px;
        @media #{$tab}{
            font-size: 30px;
            margin-bottom: 20px;
            line-height: 40px;
        }
    }
    .about_content{
        @media #{$tab}{
            margin-top: 20px;
        }
    }
}
/*************** about us part css end ***************/