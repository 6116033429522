/******** sub menu css start *********/
.header_part{
    .sub_menu{
        position: relative;
        @media #{$tab}{
            text-align: center;
            padding: 10px 0;
        }
        p{
            text-transform: uppercase;
            font-weight: 300;
            padding: 12px 0;
            line-height: 15px;
            color: $font_2;
            font-size: 12px;
        }
        a{
            text-transform: uppercase;
            color: $font_2;
            font-weight: 300;
            padding: 12px 0;
            display: inline-block;
            line-height: 16px;
            font-size: 12px;
            &:hover{
                color: $base_color;
            }
        }
        .select_option{
            margin-right: 50px;
        }
        .left_sub_menu{
            .select_option{
                display: inline-block;
                float: left;
                position: relative;
                z-index: 9;
                @media #{$tab}{
                    text-align: center;
                    float: inherit;
                }
                a{
                    span{
                        text-transform: uppercase;
                        color: $font_2;
                        margin-right: 20px;
                        position: relative;
                        @media #{$small_mobile}{
                            margin-right: 10px;
                        }
                        &:after{
                            position: absolute;
                            content: "";
                            right: -10px;
                            top: 3px;
                            width: 1px;
                            height: 12px;
                            background-color: #d6d9db;
                            @media #{$small_mobile}{
                                right: -5px;
                            }
                        }
                        &:last-child{
                            &:after{
                                width: 0;
                            }
                        }
                        &:hover{
                            color: $base_color;
                        }
                    }
                }
                
                .select_option_list{
                    padding: 28px 40px;
                    background-color: $font_2;
                    position: absolute;
                    opacity: 0;
                    visibility: hidden;
                    @include transform_time(.5s);
                    margin-top: 20px;
                    @media #{$small_mobile}{
                        padding: 20px 22px 25px;
                    }
                    .single_select_option{
                        width: 235px;
                        display: block;
                        margin: 20px 0;
                        text-align: left;
                        p{
                            color: #545759;
                            text-transform: uppercase;
                            margin-bottom: 10px;
                            padding: 0;
                        }
                        .select2-container--default .select2-selection--single{
                            color: $p_color;
                            border: 1px solid $p_color;
                            border-radius: 0;
                            background-color: transparent;
                            height: 40px;
                        }
                        
                        .select2-container--default .select2-selection--single .select2-selection__rendered {
                            color: $p_color;
                            line-height: 40px;
                            font-size: 12px;
                            padding-left: 17px;
                            padding-right: 17px;
                        }
                        .select2-container--default .select2-selection--single .select2-selection__arrow {
                            height: 26px;
                            position: absolute;
                            top: 6px;
                            right: 1px;
                            width: 20px;
                        }
                    }
                    .country_list{
                        width: 100%;
                        

                    }
                    .btn_1{
                        margin-top: 15px;
                        padding: 10px 39px;
                        font-size: 13px;
                        font-weight: 500;
                        text-transform: capitalize;
                    }
                }
            }
            .list_visiable{
                .select_option_list{
                    visibility: visible;
                    opacity: 1;
                    margin-top: 0;
                } 
            }
        }
        .right_sub_menu{
            text-align: right;
            @media #{$tab}{
                text-align: center;
            }
            ul{
                padding: 0;
                margin: 0;
                li{
                    list-style: none;
                    display: inline-block;
                    a{
                        margin-left: 30px;
                        margin-right: 15px;
                        line-height: 10px;
                        @media #{$medium_device}{
                            margin-left: 15px;
                            margin-right: 15px;
                        }
                        @media #{$small_mobile}{
                            margin-left: 6px;
                            margin-right: 6px;
                        }
                        i{
                            margin-right: 12px;
                            @media #{$small_mobile}{
                                margin-right: 3px;
                            }
                        }
                    }
                }
            }
            .cart_menu{
                position: relative;
                @include transform_time(.5s);
                z-index: 7;
                .cart_iner{
                    width: 315px;
                    padding: 40px 30px;
                    background-color: $font_2;
                    position: absolute;
                    opacity: 0;
                    visibility: hidden;
                    text-align: left;
                   
                    @include transform_time(.5s);
                    top: 60px;
                    right: 0;
                    @media #{$small_mobile}{
                        width: 275px;
                        padding: 20px 15px;
                        right: -176%;
                    }
                    span{
                        color: $white;
                    }
                    p{
                        text-transform: capitalize;
                        color: #8f8f8f;
                    }
                    img{
                        max-width: 80px;
                        padding: 20px;
                        background-color: #8f8f8f;
                    }
                    .close_icon {
                        color: $white;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        cursor: pointer;
                        i{
                            @include transform_time(.5s);
                            &:hover{
                                color: $base_color;
                            }
                        }
                    }
                    .media{
                        align-items: center;
                        margin-bottom: 20px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                    .media-body{
                        position: relative;
                        a{
                            font-size: 13px;
                            line-height: 18px;
                            margin-bottom: 10px;
                            padding: 0;
                            display: inline-block;
                            color: $p_color;    
                            margin-right: 0;
                            margin-left: 0;
                            text-transform: capitalize;
                        }
                    }
                    .total_price{
                        margin: 45px 0 20px;
                        border-top: 1px dashed $white;
                        padding-top: 15px;
                        p{
                            text-transform: capitalize;
                            font-size: 16px;
                        }
                        span{
                            font-size: 21px;
                        }
                        a{
                            margin-left: 0;
                        }
                    }
                    .btn_1, .btn_2{
                        display: block;
                        margin-left: 0;
                        text-align: center;
                        margin-right: 0;
                        text-transform: capitalize;
                        font-size: 13px;
                        font-weight: 500;
                    }
                    .btn_2{
                        margin-bottom: 15px;
                    }
                    .btn_1{
                        margin-top: 0;
                        
                    }
                }
                &:hover{
                    .cart_iner{
                        opacity: 1;
                        visibility: visible;
                        top: 40px;
                        display: block;
                        right: 0;
                        @media #{$small_mobile}{
                            top: 40px;
                            display: block;
                            right: -176%;
                        }
                    }
                }
            }
        }
        .user_account_iner{
            a{
                margin-left: 0 !important;
            }
            .account_btn{
                text-align: center;
                display: flex;
                justify-content: space-between;
                .sing_up, .login{
                    display: inline-block;
                    padding: 14px 40px;
                    color: $white;
                    background-color: $base_color;
                    border: 1px solid $base_color;
                    margin-right: 0;
                    font-size: 13px;
                    font-weight: 500;
                    text-transform: capitalize;
                }
                .sing_up{
                    border: 1px solid #343739;
                    background-color: transparent;
                    padding: 14px 36px;
                    &:hover{
                        border: 1px solid $base_color;
                        background-color: $base_color;
                        color: $white;
                    }
                }
                .login{
                    &:hover{
                        border: 1px solid #343739;
                        background-color: transparent;
                    }
                }
            }
            .user_account_details{
                margin: 25px 0 15px;
                a{
                    color: #8f8f8f;
                    display: block;
                    margin: 0 10px;
                    text-transform: capitalize;
                    padding: 10px 0;
                    @include transform_time(.5s);
                    i{
                        margin-right: 30px;
                        font-size: 15px;
                        @include transform_time(.5s);
                    }
                    &:hover{
                        color: $white;
                        i{
                            color: $base_color;
                        }
                    }
                }
            }
            .log_out{
                border-top: 1px dashed #444749;
                color: #8f8f8f;
                display: block;
                text-transform: capitalize;
                padding-top: 30px;
                i{
                    margin-right: 30px;
                    font-size: 15px;
                }
                &:hover{
                    color: $white;
                    i{
                        color: $base_color;
                    }
                }

            }
        }
        .cart_menu_item .ti-bag{
            position: relative;
            &:after {
                position: absolute;
                bottom: 0;
                width: 5px;
                height: 5px;
                content: "";
                background-color: $base_color;
                border-radius: 50%;
                left: 9px;
                top: 9px;
            }
        }
    }
    .main_menu{
        padding: 40px 0;
        background-color: $white;
        .main_logo{
            display: flex;
            justify-content: space-between;
            align-items: center;
            img{
                @media #{$tab}{
                    max-width: 150px;
                }
            }
            .mega_menu_icon{
                position: relative;
                cursor: pointer;
                &:before{
                    position: absolute;
                    content: "";
                    height: 50px;
                    width: 50px;
                    border: 1px solid #ddd;
                    left: -34px;
                    top: -12px;
                    cursor: pointer;
                    @media #{$tab}{
                        left: -50px;
                    }
                }
                span{
                    position: relative;
                    z-index: 1;
                    margin: 4px 0;
                    display: table-caption;
                    &:after{
                        position: absolute;
                        content: "";
                        width: 20px;
                        height: 1px;
                        background-color: $font_2;
                        right: 0;
                        @media #{$tab}{
                            right: 15px;
                        }
                    }
                    &:nth-child(1){
                        &:after{
                            width: 12px;
                        }
                    } 
                    &:nth-child(3){
                        &:after{
                            width: 16px;
                        }
                    }
                }
            }
        }
        .category_box{
            @media #{$tab}{
                margin-top: 30px;
            }
            .input-group-prepend{
                width: 200px;
                background-color: #f4f7f9;
                @media #{$small_mobile}{
                    width: 100%;
                    order: 2;
                }
                .select2-container{
                    width: 100% !important;
                    @media #{$small_mobile}{
                       display: none;
                    }
                } 
            }
            .input-group-append{
                height: 50px;
                width: 50px;
                line-height: 50px;
                text-align: center;
                background-color: $base_color;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                i{
                    color: $white;
                    font-size: 15px;
                    font-weight: bold;
                }
            }
            .select2-container .select2-selection--single{
                height: 50px;
            }
            .select2-container--default .select2-selection--single .select2-selection__rendered{
                line-height: 48px;
                padding-left: 25px;
                background-color: #f4f7f9;
                font-size: 13px;
                font-weight: 300;
            }
            .select2-container--default .select2-selection--single .select2-selection__arrow {
                top: 11px;
                right: 20px;
            }
            .select2-container--default .select2-selection--single {
                border: 1px solid #e4e7e9;
                border-radius: 0;
                @media #{$small_mobile}{
                    border-top: 1px solid transparent;
                }
            }
            .select2-container--default .select2-selection--single .select2-selection__arrow b {
                border-color: $font_2 transparent transparent transparent;
                border-style: solid;
                border-width: 5px 4px 0 4px;
                height: 0;
                left: 50%;
                margin-left: -4px;
                margin-top: -2px;
                position: absolute;
                top: 50%;
                width: 0;
            }
        }
        .category_box_input{
            height: 50px;
            padding: 10px 24px;
            
        }
        ::placeholder{
            font-size: 13px;
            font-weight: 300;
            color: $font_2;
        }
        .main_menu_btn{
            float: right;
            .menu_btn_1{
                text-transform: uppercase;
                color: $font_2;
                display: inline-block;
                margin-left: 55px;
                font-weight: 500;
                @include transform_time(.5s);
                &:hover{
                    color: $base_color;
                }
                @media #{$tab}{
                    margin-left: 20px;
                }
                @media #{$medium_device}{
                    margin-left: 8px;
                }
                 @media #{$big_screen}{
                    margin-left: 35px;
                }
            }
        }
        .select2-dropdown .select2-dropdown--below{
            background-color: $font_2;
        }
    }
    &::-webkit-scrollbar {
        width: 15px;
        height: 15px;
    }
    
    &::-webkit-scrollbar-track-piece  {
        background-color: #C2D2E4;
    }
    
    &::-webkit-scrollbar-thumb:vertical {
        height: 30px;
        background-color: #0A4C95;
    }
}
.single_page_menu_item{
    @media #{$tab}{
        .main_menu_btn{
            margin-top: 34px;
            float: inherit !important;
            text-align: center;
        }
    }
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $white;
    color: $font_2;
}
.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: $white;
}
.category_list_items{
    background-color: $font_2;
    color: $p_color;
    font-size: 13px;
    border-radius: 0 !important;
    border-color: $font_2;
    .select2-search__field{
        background-color: #343739;
        border-radius: 0;
        border: 1px solid #343739 !important;
        color: $white;
        padding: 6px 10px;
    }
    .select2-results__option--highlighted[aria-selected] {
        background-color: transparent;
        color: $p_color;
    }
    .select2-results__option[aria-selected=true] {
        background-color: transparent;
    }
    .select2-results>.select2-results__options {
        max-height: 240px;
        overflow-y: auto;
    }
    .select2-results__option--highlighted{
        @include transform_time(.5s);
        &:hover{
            color: $base_color
        }
    }
}
:focus {
    outline: -webkit-focus-ring-color auto 0;
}
.submenu_icon{
    position: relative;
    z-index: 1;
    &:after{
        border-color: #888 transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        height: 0;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
        top: 47%;
        width: 0;
        content: "";
        right: 0;
        @media #{$tab}{
            right: -8px;
        }
    }
}
.select2-container--default .currance_list_search .select2-selection__arrow b{
    border-color: transparent !important;
    border-style: solid;
    border-width: 0 !important;
    position: relative;
    z-index: 1;
    &:after{
        position: absolute;
        content:  "\e61a";
        font-family: 'themify';
        right: 2px;
        top: -10px;
    }
}
.select2-container--open .currance_list_search .select2-selection__arrow b{
    border-color: transparent !important;
    border-style: solid;
    border-width: 0 !important;
    position: relative;
    z-index: 1;
    &:after{
        position: absolute;
        content:  "\e622";
        font-family: 'themify';
    }
}

.currance_list_search {
    
    .select2-search--dropdown{
        padding: 10px 30px 0 30px; 
    }
    .select2-results{
        padding: 20px 30px 30px;
    }
    .select2-results__option{
        padding: 10px 0;
        line-height: 15px;
        @include transform_time(.5s);
        &:hover{
            color: $base_color;
        }
    }
}
.category_list_items {
    width: 230px !important;
    .select2-search--dropdown{
        padding: 30px 30px 0 30px;
    }
    .select2-results{
        padding: 20px 30px 30px;
    }
    .select2-results__option{
        padding: 10px 0;
        line-height: 15px;
    }
}
@media #{$small_mobile}{
    .header_part .sub_menu .right_sub_menu .user_account .cart_iner {
        right: -22%;
    }
    .header_part .sub_menu .right_sub_menu .user_account:hover .cart_iner {
        right: -22%;
    }
}
    
// ::-webkit-scrollbar {
//     width: 15px;
//     height: 15px;
//     -moz-user-select: #C2D2E4;
// }

// ::-webkit-scrollbar-track-piece  {
//     background-color: #C2D2E4;
// }

// ::-webkit-scrollbar-thumb:vertical {
//     height: 30px;
//     background-color: $base_color;
//     -moz-user-select: $base_color;
// }


