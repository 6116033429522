/************ return&exchange part css here  *************/
.return_part{
    @media #{$tab}{
        padding-bottom: 30px;
    }
    .single_return_part, .exchange_part{
        background-color: $section_bg;
        padding: 50px;
        margin-bottom: 80px;
        @media #{$tab}{
            padding: 25px;
            margin-bottom: 30px;
        }
        &:last-child{
            margin-bottom: 0; 
        }
        h5{
            font-size: 16px;
            margin-bottom: 13px;
        }
        ul{
            padding: 0;
            margin: 0;
            list-style: none;
            margin-top: 7px;
            li{
                color: $p_color;
                font-size: 14px;
                font-weight: 300;
                margin: 20px 0;
                padding-left: 25px;
                position: relative;
                z-index: 1;
                &:after{
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 5px;
                    width: 5px;
                    height: 5px;
                    background-color: $font_2;
                }
            }
        }
        .btn_1{
            margin-top: 20px;
        }
        .btn_2{
            background-color: $white;
            color: $font_2 !important;
            margin-top: 25px;
            &:hover{
                background-color: $base_color;
                color: $white !important;
            }
        }
    }
}
/************ return&exchange part css end  *************/