/************ custom wishlist css here **************/
.dashboard_part{
    .product_img {
        background-color: $white;
        min-height: 250px;
    }
    .pagination_part{
        margin-top: 20px;
    }
}
/************ custom wishlist css end **************/