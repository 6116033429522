/************** rtl css ****************/
.rtl{
    .header_part{
        .btn_1, .btn_2{
            padding: 14px 14px !important;
        }
        .sub_menu {
           
            .right_sub_menu{
                text-align: left;
                .cart_menu{
                    .cart_iner{
                        right: auto;
                        left: 0;
                        @media #{$tab}{
                            right: auto;
                            left: -146%;
                        }
                        .close_icon {
                            right: auto;
                            left: 0;
                        }
                    }
                    &:hover{
                        .cart_iner{
                            right: auto;
                            left: 0;
                            @media #{$tab}{
                                right: auto;
                                left: -146%;
                            }
                        }
                    }
                    .user_account_iner{
                        @media #{$tab}{
                            right: auto;
                            left: 0;
                        }
                        &:hover{
                            .cart_iner{
                                right: auto;
                                left: 0;
                                @media #{$tab}{
                                    right: auto;
                                    left: -146%;
                                }
                            }
                        }
                    }
                }
                
                .user_account{
                    .user_account_iner{
                        @media #{$tab}{
                            right: auto;
                            left: 0;
                        }
                    }
                    &:hover{
                        .user_account_iner{
                            @media #{$tab}{
                                right: auto;
                                left: 0 !important;
                            }
                        }
                    }
                }
                ul {
                    @media #{$tab}{
                        display: flex;
                        justify-content: space-between;
                    }
                   li a {
                        margin-left: 0;
                        margin-right: 50px;
                        @media #{$tab}{
                            margin-right: 10px;
                            margin-left: 10px;
                        } 
                        @media #{$medium_device}{
                            margin-right: 20px;
                            margin-left: 10px;
                        }
                    } 
                }
                .cart_menu .cart_iner {
                    .log_out{
                        margin-left: 0;
                        margin-right: 0 !important;
                    }
                    text-align: right;
                }
                .user_account_details{
                    a, .log_out{
                        position: relative;
                        padding-right: 30px;
                        margin: 10px 0;
                        i{
                            right: 0;
                            position: absolute;
                            margin-right: 0;
                            top: 8px;
                        }
                    }
                }
                .log_out{
                    position: relative;
                    padding-right: 30px;
                    margin: 10px 0;
                    i{
                        right: 0;
                        position: absolute;
                        margin-right: 0;
                        top: 27px;
                    }
                }
            }
            .left_sub_menu .select_option {
                float: right;
                margin-right: 0;
                margin-left: 50px;
                @media #{$tab}{
                    margin-right: 15px;
                    margin-left: 40px;
                }
                @media #{$medium_device}{
                    margin-right: 0;
                    margin-left: 40px;
                }
            }
            .left_sub_menu .select_option .select_option_list {
                top: 40px;
            }
           
            
        }
       
        .main_menu{
            .main_menu_btn{
                float: left;
                .menu_btn_1 {
                    margin-left: auto;
                    margin-right: 55px;
                    @media #{$tab}{
                        margin-right: 5px;
                        margin-left: 5px;
                    }
                    @media #{$medium_device}{
                        margin-right: 8px;
                    }
                }
            } 
            .category_box .select2-container--default .select2-selection--single .select2-selection__arrow {
                right: auto;
                left: 20px;
            }
            .category_box .select2-container--default .select2-selection--single .select2-selection__rendered {
                padding-left: 25px;
                padding-right: 25px;
            }
            .main_logo .mega_menu_icon {
                margin-left: 15px;
                margin-bottom: 3px;
                @media #{$tab}{
                    margin-left: 50px;
                }
            }
            .single_page_menu .show .dropdown-toggle::before {
                right: auto;
                left: 14px;
            }
        }
        
    } 
    .submenu_icon:after {
        right: 36px;
        @media #{$tab}{
            right: 0;
        }
        @media #{$medium_device}{
            right: 7px;
        }
    }
    .left_sub_menu{
        @media #{$tab}{
            display: flex;
            justify-content: space-between;
        }
        .submenu_icon:after {
            right: 0;
            @media #{$tab}{
                right: -14px;
            }
        }
    }

    //megamenu part css
    .side-menu{
        h4{
            padding-left: auto;
            padding-right: 40px;
        }
        nav .nav > li > a i {
            margin-left: 25px;
            margin-right: 0;
        }
        @media #{$big_device}{
            nav .nav > li > .mega-menu {
                right: 100% !important;
                top: 0 !important;
                min-width: 292%;
            }
            nav .nav > li > .mega-menu .spn-content {
                margin-top: -1px;
                margin-right: -15px;
            }
        }
        @media #{$medium_device}{
            nav .nav > li > .mega-menu {
                right: 100% !important;
                top: 0 !important;
                min-width: 196%;
            }
            nav .nav > li > .mega-menu .spn-content {
                margin-top: -1px;
                margin-right: -15px;
            }
            .show .dropdown-toggle::before {
                right: auto;
                left: -1px;
                transform: rotate(180deg);
            }
        }
    }
    .single_mega_menu_product .media .media-body {
        padding-left: auto;
        padding-right: 25px;
    }
    
    @media #{$big_device}{
        .show .dropdown-toggle::before {
            right: auto;
            left: -1px;
            transform: rotate(180deg);
        }
    }
    .banner_part {
        .user_cupon .user_cupon_iner .user_cupon_details {
            padding-right: 11%;
        }
    }
    .product_details_part .select_address ul li {
        text-align: right;
    }

    @media #{$tab}{
        .single_page_menu{
            margin-right: 0;
            margin-left: 15px;
        }
    }

    // product part css
    .best_product_list_iner{
        .product_btn {
            margin-left: 75px !important;
            margin-right: 0 !important;
        }
        .product_text{
            .product_btn {
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
        }
    }
    .best_product_list, .feature_product, .feature_brands{
        .owl-nav {
            right: auto;
            left: 0;
            top: -46px;
            display: flex;
            flex-direction: row-reverse;
        }
    }

    // 
    .product_tricker {
        text-align: right;
    }
    .pagination {
        flex-direction: row-reverse;
    }

    // product details
    .product_details_part{
        .single_details_content {
            position: relative;
            padding-left: 0;
            padding-right: 85px;
            margin: 10px 0 0;
            align-items: center;
            @media #{$tab}{
                padding-right: 0;
            }
        }
        .product_details .details_product_price span {
            text-decoration: red line-through;
            margin-left: 0;
            margin-right: 25px;
        }
        .single_details_content h5 {
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 300;
            margin-left: 0;
            margin-right: 0;
            right: 0;
            @media #{$tab}{
                margin-bottom: 15px;
                margin-left: 35px;
            }
        }
        .product_details ul li:first-child {
            margin: 7px 0 7px 0px;
        }
        .cs_color_btn {
            margin-left: 0;
            margin-right: 40px;
        }
        .social_icon {
            margin-top: 48px;
        }
        .product_details .product_details_review .review_icon {
            margin-right: 0;
            margin-left: 15px;
        }
    }
    .category_part .category_sidebar .single_category .single_category_option ul li ul {
        padding-left: 0;
        padding-right: 15px;
    }
    @media #{$medium_device}{
        .single_page_menu_item .side-menu nav .nav > li > .mega-menu {
            right: 100% !important;
            top: 0 !important;
            min-width: 207%;
            transform: translate3d(0px, 0px, 0px) !important;
        }
    }

    

    // product style
    .media_style .single_product_img {
        margin-right: 0px;
        margin-left: 25px;
    }
    .product_list_tittle {
        text-align: right;
    }

    //breadcrumb css
    .breadcrumb_cs {
        .breadcrumb_content{
            .troggle_btn{
                .toggle-label input[type=checkbox]:checked + .back .toggle {
                    margin-right: 82px;
                }
                .toggle-label .label.off {
                    left: 0px;
                    right: auto;
                }
                .toggle-label .label.on {
                    right: 0px;
                    left: auto;
                }
            } 
        } 
        
    }

    // nice select
    .nc_select{
        text-align: right !important;
        &:after {
            top: 4px;
            right: auto;
            left: 20px;
        }
        .list li {
            text-align: right;
        }
    }

    //Return & Exchange Policy
    .return_part{
        .single_return_part ul li, .exchange_part ul li {
            padding-left: 0;
            padding-right: 25px;
        }
        .single_return_part ul li:after, .exchange_part ul li:after {
            top: 8px;
            right: 0;
        }
    }

    // checkbox css
    .checkout_form{
        .billing_address .product_ceck ul li a {
            margin-right: 15px;
            margin-left: auto;
        }
    } 

    //customer account
    .dashboard_part{
        .nc_select:after {
            top: 13px;
        }
        .dashboard_sidebar a {
            padding: 14px 80px 14px 50px;
            @media #{$medium_device}{
                padding: 14px 55px 14px 15px;
            }
            i {
                position: absolute;
                right: 40px;
                margin-right: 0;
                top: 18px;
                @media #{$medium_device}{
                    right: 23px;
                }
            }
        }
        .order_item .single_order_item .order_item_name p {
            margin-left: auto;
            margin-right: 20px;
        }
    }
    .order_details_status ul li {
        margin-right: 0;
        margin-left: 70px;
        @media #{$tab}{
            margin-right: 0;
            margin-left: 20px;
        }
        @media #{$medium_device}{
            margin-right: 0;
            margin-left: 25px;
        }
    }
    .refund_disputes_content{
        .disputes_content_progress .disputes_content_item .process_count:after {
            left: -200%;
            right: auto;
        
        }
        .step_complect .process_count:after {
            right: -200%;
            left: auto;
        }
    } 

    //marchant css
    #timeline .timeline-item:nth-child(even) .timeline-content {
        float: left;
    }
    #timeline {
        .timeline-item {
            &:after{
                background: url(../img/time_line_2.png) no-repeat;
                right: -31%;
                @media #{$large_mobile}{
                    right: -29%;
                }
            }
            &:nth-child(even) {
                &:after{
                    background: url(../img/time_line_1.png) no-repeat;
                    right: -41%;
                    @media #{$large_mobile}{
                        left: 13%;
                    }
                }
            }
            &:last-child{
                &:after{
                    display: none;
                }
            }
        }
    }
    .pricing_part{
        .price_truggle p {
            line-height: 16px;
            padding-right: 18px;
        }
        .single_pricing_part{
            text-align: center;
        }
    } 
    .ferquently_question_part{
        .accordion {
            text-align: right;
            &:after{
                right: auto !important;
                left: 18px;
            }
        }
    } 
    .ferquently_question_part .accordion:after {
        content: "\e61a";
        color: #777;
        font-weight: bold;
        float: right;
        margin-left: 5px;
        font-family: 'themify';
        right: 18px;
        position: absolute;
        top: 35%;
    }
    .pricing_part{
        .price_truggle p {
            padding-right: auto;
            padding-left: 15px;
        }
    }

    //login css
    .register_part{
        .register_form_iner .checkbox .cs_checkbox {
            margin-right: 0;
            margin-left: 15px;
        }

    } 
    .forgot_pass_btn {
        float: left;
    }

    //category css
    .category_part{
        .nice-select {
            float: right;
            text-align: right !important;
            padding: 0 15px;
            &:after {
                top: 45%;
                right: auto;
                left: 20px;
            }
            .list{
                width: 258px;
            }
            .option{
                text-align: right;
            }
        }
    }

    //customer account
    .dashboard_item {
       .single_dashboard_item .single_dashboard_text {
            padding-left: 0;
            padding-right: 25px;
       } 
       
    }

    //blog part css
    .blog_sidebar{
      .single_blog_sidebar .single_sidebar_post .single_post_content {
        padding-left: 0;
        padding-right: 15px;
      }
     
    }
    .single_blog_post .single_blog_post_content {
        padding-left: 0;
        padding-right: 30px;
    }
    .author_details .author_details_content {
        padding-left: 0;
        padding-right: 30px;
    }
    .comment_area .media-body {
        padding-left: 0;
        padding-right: 30px;
    }

    //career css
    .job_post .single_job_details{
       ul {
            margin: 45px 0 5px;
            list-style: none;
            li:after {
                position: absolute;
                left: auto;
                top: 6px;
                width: 5px;
                height: 5px;
                background-color: #222;
                content: "";
                right: -37px;
            }
        }
    }
}
/************** rtl css end ****************/