/********* work process scss *********/
.work_process{
    overflow: hidden;
    .section_tittle{
        p{
            margin-bottom: 106px;
            @media #{$tab}{
                margin-bottom: 14px;
            }
            @media #{$tab_device}{
                margin-bottom: 80px;
            }
        }
    }
    .work_process_single{
        text-align: center;
        padding: 51px 50px 40px;
        border: 1px solid #e2e6eb;
        width: 45%;
        position: relative;
        img{
            position: absolute;
            top: -35px;
            left: 0;
            right: 0;
            margin: 0 auto;
            background-color: $white;
            padding: 0 20px;
        }
        h4{
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 28px;
            @media #{$tab}{
                margin-bottom: 15px;
            }
        }
    }
    .left_line{
        position: relative;
        z-index: 1;
        &:after{
            position: absolute;
            left: 0;
            top: 10%;
            
        }
    }
}

// Mixins and Placeholders
%clearfix {
	&:after, &:before {
		content: '';
		display: block;
		width: 100%;
		clear: both;
	}
}

@mixin prefix($prop, $val) {
	@each $prefix in '-webkit-', '-moz-', '-ms-', '' {
		#{$prefix}#{$prop}: $val;
	}
}

// Timeline
#timeline {
	width: 100%;
    margin: 0 auto;
    position: relative;
	@include prefix(transition, all .4s ease);
	.timeline-item {
		position: relative;
		display: inline-block;
        width: 100%;
        &:after{
            position: absolute;
            content: "";
            right: -40%;
            top: 27%;
            background: url(../img/time_line_1.png) no-repeat;
            width: 100%;
            z-index: 1;
            height: 100px;
            @media #{$large_mobile}{
                right: -29%;
            }
        }
		.timeline-content {
			width: 39%;
            @include prefix(transition, all .3s ease);
            background-color: $white;
            position: relative;
            z-index: 3;
        }
        &:nth-child(even) {
            &:after{
                position: absolute;
                content: "";
                right: 0;
                left: 32%;
                top: 27%;
                background: url(../img/time_line_2.png) no-repeat;
                @media #{$large_mobile}{
                    left: 13%;
                }
            }
            .timeline-content {
                float: right;
            }
        }
        &:last-child{
            &:after{
                display: none;
            }
        }
	}
}

@media #{$small_mobile}{
    #timeline {
		&:before {
			left: 0;
		}
		.timeline-item {
            margin-top: 60px;
			.timeline-content {
				width: 100%;
				&:before, &.right:before {
					left: 10%;
					margin-left: -6px;
					border-left: 0;
				}
			}
		}
	}
}
@media #{$large_mobile}{
    #timeline {
		&:before {
			left: 0;
		}
		.timeline-item {
            margin-top: 60px;
			.timeline-content {
				width: 60%;
				&:before, &.right:before {
					left: 10%;
					margin-left: -6px;
					border-left: 0;
				}
			}
		}
	}
}
