/************ send message form css here **************/
.send_message_form{
    h4{
        font-size: 16px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 45px;
        @media #{$tab}{
            margin-bottom: 30px;
        }
    }
    @media #{$tab}{
        .btn_1{
            margin-top: 20px;
        }
    }
    label{
        font-size: 11px;
        font-weight: 300;
        text-transform: uppercase;
    }
    textarea{
        width: 100%;
        height: 140px;
        border: 1px solid $border_color_1;
        font-size: 14px;
        color: $p_color;
        padding: 17px 20px;
        font-weight: 300;
        font-size: 14px;
    }
    ::placeholder{
        color: $p_color;
        font-weight: 300;
        font-size: 14px;
    }
    input[type=file] {
        color: $p_color;
        margin-top: 7px;
    }
    .cs_checkbox{
        label{
            font-size: 14px;
            text-transform: capitalize;
            color: #8f8f8f;
            margin-left: 15px; 
            margin-top: 2px;
        }
    }
}
.message_history{
    margin-top: 80px;
    @media #{$small_mobile}{
        margin-top: 40px;
    }
    @media #{$large_mobile}{
        margin-top: 40px;
    }
    .single_message_history{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
        @media #{$small_mobile}{
            display: inherit;
        }
        @media #{$large_mobile}{
            display: inherit;
        }
    }
    .admin_name{
        display: inline-block;
        float: left;
        text-align: right;
        flex: 25% 0 0;
        padding-right: 30px;
        @media #{$small_mobile}{
            display: inherit;
            float: none;
            margin-bottom: 10px;
        }
        @media #{$large_mobile}{
            display: inherit;
            float: none;
            margin-bottom: 10px;
        }
        h5{
            font-size: 16px;
            margin-bottom: 5px;
        }
    }
    .replay_content{
        padding: 30px 35px;
        background-color: #d7c4eb;
        flex: 75% 0 0;
        border-radius: 30px 30px 30px 0;
    }
    .client{
        @media #{$small_mobile}{
            .admin_name{
                margin-top: 15px;
            }
        }
        @media #{$large_mobile}{
            .admin_name{
                margin-top: 15px;
            }
        }
        .replay_content{
            background-color: #ffeeb1;
            border-radius: 30px 30px 0 30px;
        }
    }
}
/************ send message form css end ***************/