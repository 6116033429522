/*********** pagination part css here ************/
.pagination_part{
    border-top: 1px solid $border_color_1;
    margin-top: 80px;
}
.pagination{
    margin-bottom: 0;
    justify-content: center;
    border-top: 0 solid transparent;
    border-radius: 0;
    .page-link{
        border: 0px solid transparent;
        color: $p_color;
        padding: 13px 13px;
        border-top: 1px solid transparent;
        &:hover{
            background-color: transparent;
        }
    }
    .active{
        .page-link{
            color: $font_2;
            border-top: 1px solid $font_2;
            background-color: transparent;
            border-color: $font_2;
        }
    }
    .page-link:focus {
        z-index: 2;
        outline: 0;
        box-shadow: none;
    }
}
   
/*********** pagination part css end ************/