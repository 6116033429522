/*************** custom dashboard css here ********************/
.order_details_status{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $section_bg;
    padding: 20px;
    @media #{$tab}{
        display: block;
        
    }
    @media #{$medium_device}{
        display: block;
    }
    ul{
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-between;
        @media #{$tab}{
            display: block;
            margin-bottom: 20px;
        }
        @media #{$medium_device}{
            display: block;
            margin-bottom: 20px;
        }
        li{
            list-style: none;
            text-transform: capitalize;
            margin-right: 70px;
            @media #{$tab}{
                margin-bottom: 10px;
            }
            @media #{$medium_device}{
                margin-bottom: 10px;
            }
            p{
                display: block;
                width: 100%;
                font-size: 12px;
                color: $p_color;
                font-weight: 300;
                line-height: 24px;
                span{
                    font-weight: 400;
                    color: $font_2;
                }
            }
            
        }
    } 
    .btn_1, .btn_2{
        margin-top: 0;
        padding: 12px 29px;
        border: 1px;
        @media #{$tab}{
            padding: 12px 18px;
            
        }
        @media #{$medium_device}{
            padding: 12px 18px;
        }
    }
    .btn_2{
        border: 1px solid #d7dbe3;
        background-color: $white;
        color: $font_2 !important;
        padding: 10px 29px;
        @media #{$tab}{
            padding: 10px 18px;
        }
        @media #{$medium_device}{
            padding: 10px 18px;
        }
        &:hover{
            border-color: $base_color;
            border: 1px solid $base_color;
            background-color: $base_color;
            color: $white !important;

        }
    }
}
.order_details{
   .order_item{
    padding: 40px;
    border-right: 1px solid #d7dbe3;
    width: 70%;
    float: left;
    @media #{$tab}{
        width: 100%;
        padding: 20px;
        border-right: 0px solid transparent;
    }
    @media #{$medium_device}{
        width: 100%;
        border-right: 0px solid transparent;
    }
     .single_order_item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        
        &:last-child{
            margin-bottom: 0;
        }
        .order_item_name{
            display: flex;
            align-items: center;
            img{
                background-color: $section_bg;
                max-width: 60px;
                max-height: 60px;
            }
            p{
                margin-left: 20px;
            }
        }
     }  
   }
   .single_order_part{
    margin-bottom: 30px;
    &:last-child{
        margin-bottom: 0;
    }
   }
   .order_details_iner{
    display: flex;
    align-items: center;
    border: 1px solid #d7dbe3;
    border-top: 0px solid transparent;
    @media #{$tab}{
        display: inline-block;
        width: 100%;
    }
    @media #{$medium_device}{
        display: inline-block;
    }
   }
   .order_details_btn{
    width: 30%;
    float: left;
    text-align: center;
    @media #{$tab}{
        width: 100%;
        padding: 0 20px 20px 20px;
    }
    @media #{$medium_device}{
        width: 100%;
        padding-bottom: 40px;
    }
    .btn_2{
        border: 1px solid #d7dbe3;
        background-color: $white;
        color: $font_2 !important;
        padding: 10px 29px;
        width: 225px;
        text-align: center;
        line-height: 18px;
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
        &:hover{
            border-color: $base_color;
            border: 1px solid $base_color;
            background-color: $base_color;
            color: $white !important;

        }
    }
   }
}
.custom_refund_disputes{
    .order_details_status{
        display: block;
        width: 100%;
        margin-top: 72px;
    }
}
.refund_disputes_text_iner{
    padding: 30px;
    border: 1px solid $border_color_1;
    border-top: 0px solid transparent;
    h4{
        font-size: 16px;
        margin-bottom: 17px;
        line-height: 26px;
    }
    .btn_1{
        margin-top: 22px;
    }
}
.single_coupons_item{
    padding-top: 0;
    .tablesaw thead tr:first-child th {
        padding: 0 40px 30px;
        background-color: $white;
        font-weight: 300;
        text-transform: uppercase;
        font-size: 11px;
        color: $font_2;
    }
    .cart_table_body{
        background-color: $section_bg;
        h4{
            background-image: url(../img/cupon_bg_1.png);
            height: 80px;
            background-size: 100% 100%;
            width: 160px;
            text-align: center;
            color: $white;
            line-height: 80px;
            background-repeat: no-repeat;
            margin-bottom: 0;
            @media #{$tab}{
                width: 100px;
                height: 60px;
                line-height: 60px;
            }
        }
        
        td{
            padding: 25px 40px;
            @media #{$tab}{
                padding: 10px 20px;
            }
        }
    }
    @media #{$tab}{
        .tablesaw-cell-content {
            max-width: 55% !important;
        }
        .tablesaw-stack td .tablesaw-cell-label, .tablesaw-stack th .tablesaw-cell-label {
            width: 45% !important;
        }
    }
}
/*************** custom dashboard css end *********************/
