/************* refund area css here ****************/
.refund_area{
    .order_details_btn{
        .refund_btn{
            display: block;
            color: $white;
            background-color: #06d1e9;
            border: 1px solid #06d1e9;
            width: 90px;
            margin: 0 auto;
            padding: 7px 10px;
            line-height: 14px;
        }
        .view_details_btn{
            font-size: 14px;
            font-weight: 400;
            color: $font_2;
            margin-top: 20px;
            display: inline-block;
        }
    }
}
/************* refund area css end ****************/