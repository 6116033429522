/*********** policy part css here **************/
.policy_part{
    .policy_part_iner{
        padding: 55px 40px;
        background-color: $section_bg;
        @media #{$tab}{
            padding: 30px 20px;
        }
        .single_policy_part{
            margin-bottom: 0;
            padding: 0;
            background-color: transparent;
            margin-bottom: 40px;
            &:last-child{
                margin-bottom: 0;
            }
            ul{
                li{
                    &:last-child{
                        margin: 20px 0 0;
                    }
                }
            }
        }
    }
}
/*********** policy part css end **************/