/******** project estimate css ********/
.project_estimate{
    background-color: $white;
    padding: 120px 0 90px;
    @media #{$tab}{
        padding-top: 40px;
        padding: 70px 0;
    }
    @media #{$medium_device}{
        padding: 80px 0;
    }
    .estimate_part{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .single_estimate_part{
            flex: 0 0 30.5%;
            border: 1px solid #d7dbe3;
            padding: 38px;
            text-align: center;
            margin-bottom: 30px;
            @media #{$small_mobile}{
                flex: 0 0 48%;
                padding: 20px 10px;
                margin-bottom: 4%;
            }
            @include transform_time(.5s);
            i{
                font-size: 24px;
                color: #d4d7d9;
                @include transform_time(.5s);
            }
            p{
                color: $font_2;
                margin-top: 7px;
                font-weight: 500;
                @include transform_time(.5s);
                @media #{$medium_device}{
                    line-height: 20px;
                }
            }
            &:hover{
                background-color: $base_color;
                p{
                    color: $white;
                }
                i{
                    color: $white;
                }
            }
        }
    }
    
    .estiment_content{
        padding: 55px 50px 55px;
        background-color: $section_bg;
        @media #{$small_mobile}{
            padding: 30px 20px;
        }
        h5{
            text-transform: uppercase;
            font-weight: 300;
            color: $base_color;
            letter-spacing: 1.8px;
            font-size: 12px;
        }
        h2{
            font-size: 24px;
            font-weight: 700;
            line-height: 39px;
            padding: 0px 0 10px;
            @media #{$small_mobile}{
                font-size: 20px;
                line-height: 24px;
            }
        }
        p{
            margin-top: 17px;
        }
        .form-control{
            border: 1px solid transparent;
            border-radius: 0;
            height: 50px;
            padding: .375rem 1.5rem;
            @media #{$small_mobile} {
                width: 100%;
                margin-bottom: 15px;
            }
        }
        .input-group-text{
            background-color: $base_color;
            border-radius: 0;
            border: 1px solid transparent;
            color: $white;
            font-size: 13px;
            text-transform: capitalize;
            font-weight: 500;
            padding: 13px 30px;
            cursor: pointer;
            @media #{$small_mobile} {

            }
        }
        .input-group-append{
            @media #{$small_mobile} {

            }
        }
        ::placeholder{
            font-size: 13px;
            color: $p_color;
        }
    }
}