/******** banner part css start *********/
.banner_part{
    position: relative;
    .single_banner_slider{
        background-image: -moz-radial-gradient( 47% 46%, circle closest-side, #ff0027 -1%, #b1041e 124%);
        background-image: -webkit-radial-gradient( 47% 46%, circle closest-side, #ff0027 -1%, #b1041e 124%);
        background-image: -ms-radial-gradient( 47% 46%, circle closest-side, #ff0027 -1%, #b1041e 124%);
        padding: 80px 40px 80px 60px;
        background-size: 144% 124%;
        @media #{$small_mobile}{
            padding: 60px 30px;
        }
        p{
            color: $white;
            span{
                font-weight: 700;
                font-size: 16px;
            }
        }
        h2{
            font-weight: 300;
            font-size: 35px;
            color: $white;
            margin: 6px 0 16px;
            position: relative;
            display: inline-block;
            z-index: 1;
            @media #{$small_mobile}{
                margin: 0 0 5px;
            }
            &:after{
                position: absolute;
                content: "";
                left: 0;
                bottom: 3px;
                width: 100%;
                height: 10px;
                background-color: rgba($color: #fff, $alpha: .5);
                z-index: -1;
            }
        }
    }
    .owl-dots{
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        z-index: 99;
        bottom: 15px;
        .owl-dot{
            width: 30px;
            height: 3px;
            background-color: $white;
            opacity: .5;
            margin: 0 5px;
        }
        .owl-dot.active{
            opacity: 1;
        }
    }
    .banner_product_item{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media #{$small_mobile}{
            margin-top: 30px;
        }
        @media #{$large_mobile}{
            margin-top: 30px;
        }
        .single_product_item{
            flex: 0 0 48%;
            max-width: 48%;
            margin-bottom: 10px;
            text-align: center;
            @media #{$tab_device}{
                flex: 0 0 24%;
            }
            @media #{$medium_device}{
                flex: 0 0 24%;
            }
            .single_product_item_iner{
                background-color: $white;
                padding: 21px;
                @media #{$small_mobile}{
                    padding: 15px;
                }
                @media #{$big_screen}{
                    padding: 21px 15px;
                }
                overflow: hidden;
                img{
                    max-width: 100px;
                    display: block;
                    margin: 0 auto;
                    @include transform_time(.5s);
                    @media #{$medium_device}{
                        max-width: 90px;
                    }
                }
                p{
                    color: $font_2;
                    line-height: 20px;
                    margin-top: 8px;
                }
                &:hover{
                    img{
                        transform: scale(1.2);
                    }
                }
            }
            &:hover{
                p{
                    color: $base_color;
                }
            }
        }
        
    }
    .user_cupon {
        margin-top: 23px;
        display: inline-block;
        background-color: $white;
        width: 100%;
        padding: 23px 30px;
        @media #{$tab}{
            padding: 20px;
        }
        h4{
            font-size: 14px;
            color: $font_2;
            text-transform: uppercase;
            margin-bottom: 16px;
        }
        .user_cupon_iner{
            @include background ('../img/cupon_bg.png');
            background-size: 100% 100%;
            position: relative;
            padding: 29px 0px;
            @media #{$large_mobile}{
                padding: 45px 0;
            }
            p{
                color: $white;
                line-height: 16px;
                text-transform: uppercase;
            }
            span{
                position: absolute;
                text-transform: uppercase;
                transform: rotate(-90deg);
                left: 6%;
                top: 33%;
                color: $base_color;
            }
            .user_cupon_details{
                padding-left: 36%;
            }
        }
    }
    @media #{$small_mobile}{
        .banner_img{
            margin-bottom: 20px;
        }
    } 
    @media #{$large_mobile}{
        .btn_1{
            margin-top: 10px;
        }
        .banner_img{
            margin-bottom: 20px;
        }
    }
}
.banner_product_list{
    margin-top: 15px;
    flex-wrap: wrap;
    .product_price .product_img{
        height: 89px;
        img {
            max-width: 76px;
        }
    } 
    @media #{$tab_device}{
        margin-bottom: 20px;
    }
    @media #{$medium_device}{
        margin-bottom: 20px;
    }
    .single_banner_product{
        flex: 0 0 24%;
        .product_img{
            overflow: hidden;
            img{
                @include transform_time(.5s);
            }

        }
        &:hover{
            img{
                transform: scale(1.2);
            }
        }
        @media #{$small_mobile}{
            flex: 0 0 48%;
        }
        @media #{$large_mobile}{
            flex: 0 0 48%;
        }
    }
}