
$font_style_1: 'Heebo', sans-serif;

$white: #fff;
$black: #000;
$base_color: #ff0027;

$section_bg: #f4f7f9;
$heading_color: #0a0b4d;
$p_color: #8f8f8f;
$font_1: #545759;
$font_2: #222;
$font_3: #030303;
$font_4: #8a8a8a;
$font_5: #999999;
$font_9: #999999;
$font_6: #5b5b5b;
$border_color: #fdcb9e;
$color1: #f4f4f4;
$color2: #3197EE;
$border_color_1: #e4e7e9;

$big_device: '(min-width: 1200px)';
$tab: '(max-width: 991px)';
$small_mobile:'(max-width: 575px)';
$xs_mobile:'(max-width: 420px)';
$extra_big_screen: 'only screen and (min-width: 1440px) and (max-width: 1680px)';
$big_screen:'only screen and (min-width: 1200px) and (max-width: 1440px)';
$medium_device : 'only screen and (min-width: 991px) and (max-width: 1200px)';
$tab_device:'only screen and (min-width: 768px) and (max-width: 991px)';
$large_mobile: 'only screen and (min-width: 576px) and (max-width: 767px)';
