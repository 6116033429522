@mixin background($imgpath,$position: center,$size: cover,$repeat: no-repeat) {
    background: {
        image: url($imgpath);
        position: $position;
        repeat: $repeat;
        size: $size;
    }
}
@mixin transform_time($total_time) {
    -webkit-transition: $total_time;
    transition: $total_time;
}
@mixin placeholder {
	&.placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&::-webkit-input-placeholder {
		@content;
	}
}
