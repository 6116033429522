/********* best deals css *********/
.best_product_list, .feature_product, .feature_brands{
    .owl-nav {
        position: absolute;
        right: 0;
        top: -46px;
        button{
            height: 30px;
            width: 30px;
            border: 1px solid #aaa !important;
            @include transform_time(.5s);
            &:hover{
                background-color: $base_color !important;
                border: 1px solid $base_color !important;
                color: $white !important;
            }
            i{
                line-height: 28px;
            }
        }
        .owl-prev{
            margin-right: 5px;
        }
    }
}
.best_product_list, .best_product_list_iner{
    .product_tricker:hover .product_img:after {
        opacity: 0;
        visibility: visible;
    }
}