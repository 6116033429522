/**************** custom order details here *********************/
.shiping_address_part{
    margin-top: 78px;
    .single_address_part{
        h4{
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 700;
        }
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            margin-top: 37px;
            @media #{$tab}{
                margin-top: 15px;
            }
            li{
                display: flex;
                justify-content: space-between;
                margin: 12px 0;
            }
        }
        .order_btn{
            border: 1px solid #d7dbe3;
            display: inline-block;
            background-color: #f4f7f9;
            color: $font_2;
            text-transform: capitalize;
            padding: 5px 24px;
            line-height: 15px;
            &:hover{
                background-color: $base_color;
                color: $white;
                border: 1px solid $base_color;
            }
        }
    }
    .single_address_part{
        margin-bottom: 80px;
        @media #{$tab}{
            margin-bottom: 20px;
            margin-top: 20px;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    ul {
        width: 100%;
    }
}
.custom_order_details{
    .order_details_status ul {
        width: 100%;
    }
}
/**************** custom order details end *********************/