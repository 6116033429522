/************** blog details css here *************/
.blog_details_part{
    .blog_details_img{
        img{
            width: 100%;
        }
    }
    .blog_details_content{
        padding: 72px 120px 0 120px;
        @media #{$tab}{
            padding: 15px;
        }
        @media #{$small_mobile}{
            padding: 0;
        }
        @media #{$medium_device}{
            padding: 30px;
        }
        p{
            margin: 20px 0;
            @media #{$tab}{
                margin: 10px 0;
            }
        }
    }
    .blog_details_text{
        span{
            margin: 25px 0 25px 45px;
            display: inline-block;
            font-size: 16px;
            font-style: italic;
            line-height: 30px;
            border-left: 2px solid $base_color;
            padding-left: 30px;
            @media #{$tab}{
                margin: 15px 0 15px 10px;
                padding-left: 10px;
            }
        }
    }
    .btn_2{
        padding: 8px 22px;
        font-size: 10px;
        line-height: 12px;
    }
}
.author_details{
    padding: 40px;
    background-color: $section_bg;
    margin-top: 61px;
    display: flex;
    @media #{$small_mobile}{
        margin-top: 30px;
        padding: 15px;
        display: block;
        .author_img{
            margin-bottom: 20px;
            img{
                width: 100%;
            }
        }
    }
    .author_details_content{
        padding-left: 30px;
        @media #{$small_mobile}{
            padding-left: 0;
        }
        h4{
            font-size: 16px;
            
        }
        p{
            margin: 5px 0 15px;

        }
    }
    .author_social_icon{
        a{
            border-radius: 50%;
            background-color: rgb(255, 255, 255);
            width: 36px;
            height: 36px;
            display: inline-block;
            text-align: center;
            line-height: 36px;
            color: #b5babf;
            margin-right: 10px;
        }
    }
}
.comment_area{
    margin-top: 80px;
    .author_tittle{
        display: flex;
        align-items: center;
        p{
            margin: 0;
            line-height: 15px;
        }
        h5{
            font-size: 16px;
            margin-bottom: 0;
        }
        span{
            margin: 0 20px;
        }
    }
    h4{
        font-size: 16px;
    }
    .media{
        margin-top: 50px;
        @media #{$small_mobile}{
            display: block;
        }
        img{
            max-width: 40px;
            border-radius: 50%;
        }
    }
    span{
        color: $p_color;
    }
    .btn_2{
        background-color: #f4f7f9;
        color: $font_2 !important;
    }
    p{
        margin: 9px 0 !important;
    }
    .media-body{
        padding-left: 30px;
        @media #{$small_mobile}{
            padding-left: 0;
            margin-top: 15px;
        }
    }
    .btn_2{
        margin-top: 5px;
        &:hover{
            background-color: $base_color !important;
            color: $white !important;
        }
    }
}
.comment_form{
    margin-top: 75px;
    h4{
        font-size: 16px
    }
    .form-row{
        margin-top: 45px;
        .form-group{
            margin-bottom: 25px;
        }
        label{
            margin-bottom: 0;
            text-transform: uppercase;
            display: block;
            font-size: 11px;
            font-weight: 300;
            color: $font_2;
            margin-bottom: 12px;
        }
        
        textarea, input{
            padding: 15px 20px;
            border: 1px solid $border_color_1;
            border-radius: 0;
            color: $p_color;
            display: block;
            width: 100%;
            font-weight: 300;
        }

        textarea{
            height: 160px;
        }
       
        .btn_1{
            margin-top: 5px;
        }
    }
}
/************** blog details css end *************/
