/********* feature category css start *********/
.feature_product{
    .product_list_tittle{
        .product_btn{
            margin-right: 0;
            padding: 6px 14px;
            font-size: 10px;
            line-height: 10px;
            color: $font_2;
            border: 1px solid $font_2;
            
            background-color: transparent;
            &:hover{
                background-color: $base_color;
                border: 1px solid $base_color;
                color: $white;
            }
        }
    }
    .single_feature_slide{
        .product_list_tittle{
            @media #{$small_mobile}{
                display: flex ;
            }
            h5{
                font-size: 14px;
                text-transform: capitalize;
                font-weight: 500;
            }
        }
        .product_btn{

        }
    }
}
.colum6{
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .single_feature_slide_img{
        flex: 0 0 32%;
        max-width: 32%;
        height: 85px;
        background-color: $section_bg;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        img{
            max-width: 65px;
            @include transform_time(.5s);
        }
        &:hover{
            img{
                transform: rotate(5deg) scale(1.2);
            }
        }
    }
}
.colum3{
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .single_img{
        flex: 0 0 32%;
        max-width: 32%;
        .single_feature_slide_img {
            height: 85px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $section_bg;
            margin-bottom: 10px;
            &:last-child{
                margin-bottom: 0;
            }
            img{
                max-width: 65px;
                @include transform_time(.5s);
            }
            &:hover{
                img{
                    transform: rotate(5deg) scale(1.2);
                }
            }
        }
    }
    .big_img{
        flex: 0 0 64%;
        max-width: 64%;
        background-color: $section_bg;
        .single_feature_slide_img{
            height: 180px;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                max-width: 100px;
                @include transform_time(.5s);
            }
            &:hover{
                img{
                    transform: rotate(5deg) scale(1.2);
                }
            }
        }
    }
}
.feature_brands{
    .colum3{
        background-color: $white;
        padding: 5px;
        .single_feature_slide_img {
            height: 67px;
            padding: 5px;

        }
        .single_img{
            .single_feature_slide_img {
                margin-bottom: 5px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .big_img{
            .single_feature_slide_img{
                height: 139px;
            }
        }
    }
}