/********** login css here **********/
.register_part{
    background: url(../img/login_form.png) bottom right;
    height: 100vh;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    justify-content: center;
    .register_form_iner{
        padding: 60px 40px;
        background-color: $white;
        @media #{$tab}{
            padding: 30px 15px;
        }
        h2{
            text-align: center;
            font-size: 21px;
            line-height: 30px;
        }
        p{
            font-size: 14px;
            line-height: 21px;
            margin-top: -4px;
        }
        .register_form{
            margin-top: 30px;
            label{
                text-transform: uppercase;
                display: block;
                font-size: 11px;
            }
            .nc_select{
                font-weight: 300;
            }
            input{
                border-radius: 0;
                width: 100%;
                border: 1px solid $border_color_1;
                padding: 13px 18px;
                margin-bottom: 22px;
                font-weight: 300;
                @media #{$tab}{
                    margin-bottom: 13px;
                }
            }
            ::placeholder{
                color: $p_color;
            }
        }
        .checkbox{
            display: flex;
            margin-top: 8px;
            @media #{$small_mobile}{
                margin-top: 4px;
            }
            .cs_checkbox{
                margin-right: 15px;
                @media #{$small_mobile}{
                    margin-right: 9px;
                }
            }

        }
        .cs_checkbox input:checked ~ .checkmark {
            background-color: $font_2;
            border: 1px solid $font_2;
        }
        .cs_checkbox:hover input ~ .checkmark {
            background-color: $font_2;
            border: 1px solid $font_2;
        }
        
        .register_area{
            .btn_1{
                background-color: $base_color;
                border: 1px solid $base_color;
                padding: 17px 85px;
                margin-top: 36px;
                margin-bottom: 23px;
                @media #{$tab}{
                    margin: 25px 0;
                }
                &:hover{
                    background-color: $base_color;
                    border: 1px solid $base_color;
                    color: $white !important;
                }
            }
            p{
                font-size: 15px;
                a{
                    color: $font_2;
                    text-decoration: underline;
                }
            }
        }
    }
    .cs_checkbox .checkmark {
        top: -5px;
    }
    .forgot_pass_btn{
        float: right;
        font-size: 14px;
        color: $p_color;
    }
    .Login_btn{
        font-size: 14px;
        color: $p_color;
        &:hover{
            color: $base_color;
        }
    }
}
.login_area{
    .register_area{
       span{
           display: block;
           color: $p_color;
           margin-bottom: 10px;
       }
    }
}
.register_form{
    .nice-select{
        width: 100%;
        text-transform: capitalize;
        height: 50px;
        border-radius: 0;
        line-height: 50px;
        color: $p_color;
    }
    .list{
        width: 100%;
        border-radius: 0;
        background-color: $font_2;
        color: $p_color;
        font-weight: 400;
        padding: 20px;
        li{
            @include transform_time(.5s);
            &:hover{
                color: $base_color !important;
            }
        }
    }
    .nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
        background-color: $font_2;
        color: $p_color;
        font-weight: 400;
    }
    .nice-select:after {
        border-bottom: 0px solid transparent;
        border-right: 0px solid transparent;
        content:  "\e61a";
        font-family: 'themify';
        display: block;
        height: 10px;
        margin-top: -4px;
        pointer-events: none;
        position: absolute;
        right: 20px;
        top: 9%;
        width: 10px;
        -webkit-transform-origin: 66% 66%;
        -ms-transform-origin: 66% 66%;
        transform-origin: 66% 66%;
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
        -webkit-transition: all 0.15s ease-in-out;
        transition: all 0.15s ease-in-out;
    }
    .nice-select:active, .nice-select.open, .nice-select:focus {
        border-color: $border_color_1;
    }
    .open{
        &:after {
            content:  "\e622";
            font-family: 'themify';
        }
    }
}
