/********* cart part css start *********/
.cart_part{
    background-color: $white;
    padding-top: 160px;
    @media #{$tab}{
        padding-top: 70px;
    }
    @media #{$medium_device}{
        padding-top: 80px;
    }
    .shiping_address{
        ::placeholder{
            font-size: 14px;
            color: $p_color;
        }
    }
    .single_address_part{
        margin-bottom: 35px;
        display: inline-block;
        @media #{$tab}{
            margin-bottom: 15px;
        }
        p{
            text-transform: capitalize;
        }
        h5{
            font-size: 14px;
            color: $font_2;
            font-weight: 300;
            margin-bottom: 15px;
            @media #{$tab}{
                margin-bottom: 10px;
            }
        }
        span{
            color: $font_2;
            font-weight: 300;
        }
        .select_address {
            border-radius: 0;
            width: 100%;
            text-transform: capitalize;
        }
        .input-group{
            margin-top: 20px;
            input {
                border: 1px solid $border_color_1;
                border-radius: 0;
                height: 50px;
                padding: .375rem 1.5rem;
            }
            .input-group-text{
                background-color: #ff0027;
                border-radius: 0;
                border: 1px solid transparent;
                color: $white;
                font-size: 13px;
                text-transform: capitalize;
                font-weight: 500;
                padding: 13px 30px;
                cursor: pointer;

            }
        }

    }
    .ship {
        width: 100%;

    }
   .store{
       display: flex;
       justify-content: space-between;
       border: 1px solid $border_color_1;
       align-items: center;
       padding: 10px 20px;
       
   } 

   .table thead th {
        vertical-align: bottom;
        border-bottom: 2px solid #dee2e6;
        border: 0px;
    }
    tbody {
        background-color: #f4f7f9;
        td{
            padding: 25px 40px;
            @media #{$tab}{
                padding: 10px 20px;
                display: flex;
                justify-content: space-between;
            }
            @media #{$medium_device}{
                padding: 10px 20px;
                display: flex;
                justify-content: space-between;
            }
            @media #{$big_screen}{
                padding: 25px 26px;
            }
        }
    }
    .cart_product_list{
        margin-top: -70px;
        @media #{$tab}{
            margin-top: 0px;
        }
        @media #{$medium_device}{
            margin-top: 0px;
        }
    }
    .cart_table_body {
        tr{
            align-items: center;
            border-bottom: 1px solid #ddd;
            &:last-child{
                border-bottom: 0px solid #ddd;
            }
        }
        i{
            cursor: pointer;
        }
    }
    .table-hover tbody tr:hover {
        background-color: transparent;
    }
    img {
        background-color: $white;
        padding: 20px;
        max-width: 100px;
    }
    
    .total_price{
        .single_total_price{
            display: flex;
            justify-content: space-between;
            margin-bottom: 11px;
            p{
                font-size: 14px;
                color: $font_2;
                display: block;
                line-height: 22px;
                span{
                    display: block;
                    font-size: 11px;
                    color: $p_color;
                    text-transform: uppercase;
                }
            }
        }
    }
    .total_price {
        margin-top: 12px;
    }
    .cart_btn{
        text-align: right;
        border-top: 1px solid #d7dbe3;
        margin-top: 31px;
        @media #{$tab}{
            padding-top: 25px;
        }
        .btn_2{
            color: #0f2137 !important;
            border: 1px solid #d7dbe3;
            &:hover{
                background-color: $base_color;
                color: $white !important;
            }
            
            
        }
        .btn_1{
            border: 1px solid $base_color;
            margin-left: 8px;
            @media #{$tab}{
                margin-top: 15px;
            }
        }
    }
}
.product_count {
    width: 110px;
    text-align: center;
    position: relative;
    input.qty {
        width: 100%;
        text-align: center;
        border: 1px solid #ddd;
        padding: 9px 20px;
    }
    
    .cart-qty-plus, .cart-qty-minus {
        border: 0px solid transparent;
        background-color: transparent;
    }
    .cart-qty-plus{
        position: absolute;
        right: 9px;
        top: 10px;
    } 
    .cart-qty-minus{
        position: absolute;
        left: 9px;
        top: 10px;
    }
    
    .button-container .product_count {
        border: 1px solid #ddd;
        padding: 10px 20px;
    }
}