/*********** dashboard sidebar css here *************/
.dashboard_sidebar{
    background-color: $font_2;
    padding: 50px 0;
   
    @media #{$tab}{
        padding: 30px 0;
        margin-bottom: 40px;
    }
    a{
        display: block;
        width: 100%;
        color: $p_color;
        font-size: 16px;
        font-weight: 300;
        border-left: 2px solid transparent;
        padding: 14px 50px;
        position: relative;
        z-index: 1;
        @media #{$tab}{
            padding: 14px 30px;
        }
        @media #{$medium_device}{
            padding: 14px 20px;
        }
        &:after{
            position: absolute;
            left: -2px;
            top: 0;
            width: 2px;
            height: 100%;
            content: "";
            background-color: $base_color;
            opacity: 0;
        }
        i{
            color: #444749;
            margin-right: 32px;
            @media #{$medium_device}{
                margin-right: 14px;
            }
        }
        &:hover{
            &:after{
                opacity: 1;
            }
            i{
                color: $base_color;
            }
            color: $white;
        }
    }
    .active{
        &:after{
            opacity: 1;
        }
        i{
            color: $base_color;
        }
        color: $white;
    }
}
.dashboard_item{
    .single_dashboard_item{
        display: flex;
        align-items: center;
        padding: 20px;
        border: 1px solid $border_color_1;
        margin-bottom: 30px;
        @media #{$tab_device}{
            padding: 10px;
        }
        i{
            height: 80px;
            width: 80px;
            text-align: center;
            line-height: 80px;
            display: inline-block;
            background-color: #d7c4eb;
            font-size: 36px;
            color: $white;
            @media #{$tab_device}{
                height: 50px;
                width: 50px;
                line-height: 50px;
                font-size: 25px;
            }
        }
        .single_dashboard_text{
            padding-left: 25px;
            h4{
                font-size: 24px;
                font-weight: 700;
                margin-bottom: 0;
            }
            p{
                text-transform: capitalize;
            }
        }
    }
    .wishlist{
        i{
            background-color: #ffced3;
        }
    }
    .disputes{
        i{
            background-color: #ffeeb1;
        }
    }
    .disputes{
        i{
            background-color: #bfe7dc;
        }
    }
}
/*********** dashboard sidebar css end *************/