/************ member info css here**************/
.member_info{
    .member_info_iner{
        margin-top: -50px;
        z-index: 2;
        position: relative;
    }
    .member_info_text{
        padding-left: 30px;
        margin-top: 87px;
        @media #{$small_mobile}{
            padding-left: 0;
            margin-top: 25px;
        }
        @media #{$large_mobile}{
            padding-left: 0;
            margin-top: 25px;
        }
        .member_info_details{
            margin-bottom: 12px;
            align-items: center;
            @media #{$small_mobile}{
                margin-bottom: 5px;
            }
            @media #{$large_mobile}{
                margin-bottom: 5px;
            }
            h4{
                font-size: 24px;
                margin-bottom: 0;
            }
            span{
                margin: 0 15px;
                @media #{$small_mobile}{
                    display: none;
                }
                @media #{$large_mobile}{
                    display: none;
                }
            }
            .review_icon{
                margin-right: 15px;
                i{
                    color: #ffb400;
                }
            }
        }
    }
}
/************ member info css end**************/