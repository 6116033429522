/******** ferquently question part css *******/
.ferquently_question_part{
    background-color: $white;
    @media #{$tab}{
      padding: 40px 0 70px;
    }
    .single_ferquently_question{
      border-bottom: 1px solid #d7dbe3;
      &:last-child{
        border-bottom: 0px solid transparent;
      }
      p{
        padding-bottom: 30px;
      }
    }
    .accordion {
        background-color: $white;
        color: $font_2;
        cursor: pointer;
        padding: 21px 18px 17px;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
        font-size: 16px;
        transition: 0.4s;
        position: relative;
        @media #{$tab}{
          padding-right: 45px;
        }
      }
      
      .active, .accordion:hover {
        background-color: #f4f7f9;
      }
      
      .accordion:after {
        content: "\e61a";
        color: #777;
        font-weight: bold;
        float: right;
        margin-left: 5px;
        font-family: 'themify';
        right: 18px;
        position: absolute;
        top: 35%;
      }
      
      .active:after {
        content: "\e622";
      }
      
      .panel {
        padding: 0 18px;
        background-color: #f4f7f9;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
      }
      p{
          font-size: 14px;
          line-height: 26px;
      }
}