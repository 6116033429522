/************ job post css here ************/
.job_post{
    .panel{
        padding: 0 50px;
    }
    .accordion{
        padding: 21px 50px 17px;
    }
    button.accordion.active {
        padding: 45px 50px 17px !important;
        &:after {
            top: 51%;
        }
    }
    .accordion:after {
        right: 50px;
    }
    .single_job_details{
        margin-top: 25px;
        h4{
            font-size: 14px;
            color: $font_2;
            margin-bottom: 21px;
        }
        ul{
            margin: 5px 0 45px;
            list-style:none;
            li{
                color: $p_color;
                margin-bottom: 20px;
                position: relative;
                z-index: 1;
                &:after{
                    position: absolute;
                    left: -25px;
                    top: 6px;
                    width: 5px;
                    height: 5px;
                    background-color: $font_2;
                    content: "";
                }
            }
        }
    }
    .btn_1{
        margin-top: 0;
        margin-bottom: 50px;
    }
}
/************ job post css end ************/