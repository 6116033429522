/************* product css start ***********/
.product_price{
    text-align: center;
    background-color: $white;
    padding: 10px;
    margin-top: 20px;
    position: relative;

    .product_img{
        height: 100px;
        background-color: $section_bg;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            max-width: 90px;
        }
    }

    .product_text{
        position: relative;
        padding: 25px 12px 9px;
        @media #{$small_mobile}{
            padding: 25px 8px 15px;
        }
        @media #{$big_screen}{
            padding: 25px 9px 9px;
        }
        @media #{$medium_device}{
            padding: 25px 9px 9px;
        }
        p{
            line-height: 23px;
            color: $font_2;
            @include transform_time(.5s);
            &:hover{
                color: $base_color;
            }
        }
        .product_btn{
            position: absolute;
            left: 0;
            top: -10px;
            right: 0;
            width: 64px;
            margin: 0 auto;
            
        }
    }
}
.product_list_tittle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    @media #{$small_mobile}{
        display: inherit;
    }
    h5{
        text-transform: uppercase;
        font-size: 16px;
    }
    .product_btn{
        padding: 5px 19px;
        border: 1px solid $base_color;
        margin-right: 75px;
        font-weight: 500;
        &:hover{
            background-color: transparent;
            color: $base_color;
        }
    }
}
.product_tricker{
    background-color: $section_bg;
    padding: 10px;
    .product_img{
        background-color: $white;
        height: 150px;
        text-align: center;
        margin: 0 auto;
        display: flex;
        align-items: center;
        img{
            max-width: 120px;
            margin: 0 auto;
            max-height: 125px;
        }
    }
    
    .product_text{
        padding: 15px 0 5px;
        h5{
            font-size: 13px ;
            font-weight: 300;
            color: $p_color;
            &:hover{
                color: $base_color;
            }
        }
        p{
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 13px;
            color: $font_2;
        }
        .product_btn {
            text-transform: capitalize;
            padding: 0;
            height: 20px;
            width: 55px;
            line-height: 20px;
            font-size: 10px;
            text-align: center;
        }
    }
}
.all_product{
    padding-bottom: 120px;
    text-align: center;
    @media #{$tab}{
        padding-bottom: 70px;
    }
    @media #{$medium_device}{
        padding-bottom: 70px;
    }
    
}
.product_list_tittle{
    text-align: left;
}

.product_tricker {
    background-color: $white;
    padding: 10px;
    text-align: left;
    position: relative;
    .product_img {
        background-color: $section_bg;
        position: relative;
        z-index: 1;
        overflow: hidden;
        &:after{
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $black;
            opacity: 0;
            z-index: -1;
            @include transform_time(1s);
            visibility: hidden;
        }
        img{
            @include transform_time(.5s);
            transform: scale(1);
        }
        
        .socal_icon{
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            @include transform_time(1s);
            opacity: 0;
            visibility: hidden;
            a{
                width: 40px;
                height: 40px;
                background-color: $white;
                display: inline-block;
                color: $p_color;
                font-size: 12px;
                text-align: center;
                line-height: 43px;
                border-radius: 50%;
                @include transform_time(.5s);
                &:hover{
                    background-color: $base_color;
                    color: $white;
                }
            }
        }
    }
    &:hover{
        img{
            transform: rotate(5deg) scale(1.2);
        }
    }
    .review_icon{
        i{
            color: #ffb400;
            font-size: 10px;
        }
    }
    .product_text{
        h5{
            a{
                font-size: 13px;
                line-height: 18px;
                color: $font_2;
                &:hover{
                    color: $base_color;
                }
            }
        }
        p{
            font-size: 14px;
        }
    }
    .product_review_count{
        p, span{
            font-size: 12px;
            margin-top: 5px;
            color: $p_color;
            text-transform: capitalize;
            font-weight: 300;
        }
        span{
            text-decoration: line-through;
            text-decoration-color: $base_color;
        }
    }
    &:hover{
        .product_img{
            &:after{
                opacity: .5;
                visibility: visible;
            } 
        }
        .socal_icon{
            opacity: 1;
            visibility: visible;
        }
    }
    .owl-carousel .owl-item img {
        display: block;
        width: inherit;
    }
} 

.load_more_btn{
    padding: 15px 27px;
    display: inline-block;
    text-align: center;
    color: $white !important;
    background-color: $base_color;
    margin: 0 auto;
    margin-top: 20px;
    i{
        color: $white;
        margin-right: 12px;
    }
}
.product_img_iner{
    margin: 0 auto;
}

.price_off, .new_price{
    clip-path: polygon(100% 0%, 100% 48%, 100% 100%, 0 100%, 11% 47%, 0 0);
    background-color: #b98af9;
    font-size: 10px;
    display: inline-block;
    padding: 5px 15px 5px 16px;
    line-height: 10px;
    color: $white;
    position: absolute;
    top: 24px;
    right: 0;
    z-index: 9;
}

.new_price{
    background-color: #84def4;
}

.product_slider_1, .product_slider_2{
    .product_tricker{
        margin-bottom: 0;
        background-color: $section_bg;
        overflow: hidden;
        .product_img{
            overflow: hidden;
        }
        img{
            @include transform_time(.5s);
        }
        &:hover{
            img{
                transform: rotate(5deg) scale(1.2);
            }
        }
    }
    .product_tricker .product_img {
        background-color: $white;
        position: relative;
        z-index: 1;
    }
    
}
.product_details_weiget{
    @media #{$tab}{
        margin-top: 70px;
    }
    
    h3{
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 73px;
        @media #{$tab}{
            margin-bottom: 40px;
        }
    }
}
.single_product_details_weiget{
    
    @media #{$tab}{
        .media_style{
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

.media_style{
    display: flex;
    margin-bottom: 40px;
    @media #{$tab}{
        margin-bottom: 30px;
    }
    .single_product_img{
        background-color: #f4f7f9;
        margin-right: 25px;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
        &:after{
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $base_color;
            opacity: 0;
            overflow: hidden;
            @include transform_time(.5s);
        }
        a{
            width: 50px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            border-radius: 50%; 
            background-color: $white;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            z-index: 2;
            visibility: hidden;
            opacity: 0;
            @include transform_time(.5s);
            display: inline-block;
            color: $font_2;
        }
        img{
            max-width: 100px;
            padding: 20px;
        }
    }
    &:hover{
        .single_product_img{
            &:after{
                opacity: .5;
                visibility: visible;
            }
            a{
                visibility: visible;
                opacity: 1;
                &:hover{
                    background-color: $base_color;
                    color: $white;
                }
            }
        }
    }
    .single_product_text{
        a{
            font-size: 16px;
            line-height: 24px;
            color: $font_2;
            font-weight: 300;
        }
    }
    .category_product_price{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        align-items: center;
        h4{
            font-size: 16px;
            font-weight: 700;
            display: inline-block;
            position: relative;
            z-index: 1;
            &:after{
                position: absolute;
                content: "";
                left: 0;
                bottom: 0;
                width: 100%;
                height: 8px;
                background-color: #ffced3;
                z-index: -1;
            }
        }
        span{
            font-size: 14px;
            font-weight: 300;
            text-decoration: red line-through;
        }
    }
}


.single_product_list{
    margin-bottom: 30px;
}
.client_review{
    margin-bottom: 45px;
    .single_product_img:after {
        display: none;
    }
    .single_product_img{
        background-color: transparent;
        align-items: start;
        img{
            padding: 0;
        }
    }
    .single_product_text{
        .review_icon{
            i{
                color: #ffb400;
                font-size: 12px;
            }
        }
        h3{
           font-size: 16px;
           font-weight: 500; 
           padding: 12px 0 12px;
           span{
               font-size: 13px;
               font-weight: 300;
               margin-left: 40px;
               color: $p_color;
           }
        }
    }
} 

