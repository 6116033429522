/******* pricing part ********/
.pricing_part{
    .price_truggle{
        justify-content: center;
        margin-bottom: 80px;

        align-items: center;
        @media #{$tab}{
            margin-bottom: 40px;
        } 
        @media #{$medium_device}{
            margin-bottom: 60px;
        }
        p{
            line-height: 16px;
            padding-right: 18px;
        }
        label{
            margin-bottom: 0;
        }
    }
    
    .product_tricker{
        position: relative;
        z-index: 1;
        &:after{
            position: absolute;
            content: "";
            right: -6px;
            top: -6px;
            background-image: url(../img/pricing_tricker.png);
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: top right;
            z-index: -1;
        }
    }
    .product_tricker_text {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 300;
        color: $white;
        position: absolute;
        right: -1px;
        z-index: 2;
        transform: rotate(45deg);
        top: 25px;
    }
    .single_pricing_part{
        padding: 50px;
        border: 1px solid #e2e6eb;
        text-align: center;
        padding: 47px 50px 50px;
        @media #{$tab}{
            padding: 30px;
            margin-bottom: 30px;
        }
        @media #{$medium_device}{
            padding: 30px;
            margin-bottom: 30px;
        }
        h5{
            font-size: 18px;
        }
        h2{
            font-size: 40px;
            font-weight: 700;
            margin-top: 48px;
            margin-bottom: 0;
            @media #{$tab}{
                margin-top: 20px;
                font-size: 25px;
            }
            @media #{$medium_device}{
                margin-top: 20px;
                font-size: 30px;
            }
        }
        ul{
            list-style: none;
            padding: 31px 0 0 0;
            @media #{$tab}{
                padding: 0;
            }
            @media #{$medium_device}{
                padding: 10px 0 0 0;
            }
            li{
                margin: 15px 0;
                font-size: 16px;
                color: $p_color;
                font-weight: 300;
            }
        }
        .pricing_header {
            padding-bottom: 44px;
            border-bottom: 1px solid #e2e6eb;
            @media #{$tab}{
                padding-bottom: 20px;
            }
            @media #{$medium_device}{
                padding-bottom: 20px;
            }
        }
        .btn_2{
            background-color: #f4f7f9;
            border: 1px solid #e2e6eb;
            color: #222 !important;
            margin-top: 28px;
            font-weight: 500;
            padding: 16px 47px;
            line-height: 16px;
            &:hover{
                border: 1px solid $base_color;
                background-color: $base_color;
                color: $white !important;
            }
        }
    }
}
.switch-toggle {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 20px;
    border-radius: 25px;
    background-color: #f4f7f9;
    border: 1px solid $border_color_1;
    input {
        display: none;
    }
    div {
        position: absolute;
        border-radius: 50%;
        background-color: #ff0027;
        transition: .1s ease;
        box-shadow: 2.071px 7.727px 30px 0px rgba(211, 0, 98, 0.45);
    }
    input:checked + div {
        left: 62%;
        background-color: #ff0027;
    }
}
.switch-toggle.outer div {
    width: 26px;
    height: 26px;
    top: -4px;
    left: -3px;
}









