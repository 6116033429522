/************** custom refund disputes css here ***************/
.refund_disputes_sidebar{
    .single_disputes_sidebar{
        padding: 40px;
        border: 1px solid $border_color_1;
        margin-bottom: 30px;
        @media #{$tab}{
            padding: 30px;
        }
        &:last-child{
            margin-bottom: 0;
        }
        h4{
            font-size: 16px;
            margin-bottom: 18px;
            @media #{$tab}{
                margin-bottom: 10px;
            }
        }
    }
}
.refund_disputes_content{
    .disputes_content_progress{
        width: 80%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        overflow: hidden;
        @media #{$tab}{
            width: 100%;
        }
        .disputes_content_item{
            text-align: center;
            z-index: 1;
            @media #{$tab}{
                flex: 50% 0 0;
                margin-top: 30px;
            }
            .process_count{
                height: 90px;
                width: 90px;
                border: 2px solid;
                border-radius: 50%;
                border-color: #f4f7f9;
                background-color: $white;
                line-height: 90px;
                margin: 0 auto;
                position: relative;
                z-index: 5;
                @media #{$tab}{
                    height: 50px;
                    width: 50px;
                    line-height: 50px;
                }
                @media #{$medium_device}{
                    height: 70px;
                    width: 70px;
                    line-height: 70px;
                }
                &:after{
                    position: absolute;
                    content: "";
                    right: -200%;
                    top: 50%;
                    background-color: #f4f7f9;
                    width: 200%;
                    height: 2px;
                    z-index: -1;
                    @media #{$tab}{
                        display: none;
                    }
                }
            }
            &:last-child{
                .process_count{
                    &:after{
                        display: none;
                    }
                }
            }
            a{
                height: 70px;
                width: 70px;
                line-height: 70px;
                text-align: center;
                display: inline-block;
                border-radius: 50%;
                background-color: #f4f7f9;
                font-size: 16px;
                font-weight: 700;
                color: $font_2;
                @media #{$tab}{
                    height: 40px;
                    width: 40px;
                    line-height: 40px;
                }
                @media #{$medium_device}{
                    height: 50px;
                    width: 50px;
                    line-height: 50px;
                }
            }
            p{
                color: $font_2;
                font-weight: 400;
                margin-top: 15px;
                @media #{$tab}{
                    margin-top: 10px;
                }
            }
        }
    }
    .step_complect{
        .process_count{
            height: 90px;
            width: 90px;
            border: 2px solid;
            border-radius: 50%;
            border-color: $base_color !important;
            background-color: $white;
            line-height: 90px;
            margin: 0 auto;
            position: relative;
            z-index: 5;
            &:after{
                position: absolute;
                content: "";
                right: -200%;
                top: 50%;
                background-color: #ff0027 !important;
                width: 200%;
                height: 2px;
                z-index: -1;
            }
        }
        a{
            height: 70px;
            width: 70px;
            line-height: 70px;
            text-align: center;
            display: inline-block;
            border-radius: 50%;
            background-color: #ff0027 !important;
            font-size: 16px;
            font-weight: 700;
            color: $white !important;

        }
    }
}
/************** custom refund disputes css end ***************/