/********* product description css here*********/
.product_description{
    background-color: $white;
    .nav-tabs .nav-item .nav-link, .nav-tabs .nav-link {
        background-color: #f4f7f9;
        color: $font_2;
        border-color: transparent;
        padding: 17px 41px;
        line-height: 14px;
        border-radius: 0;
        margin-right: 5px;
        @media #{$tab}{
            padding: 12px 14px;
        }
    }
    @media #{$tab}{
        .nav-tabs {
            border-bottom: 0 solid transparent;
        }
    }
    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
        color: $white;
        background-color: $font_2;
    }
    .product_description_info{
        .product_details, .item_description{
            margin-top: 45px;
            p{
                font-size: 16px;
            }
            h4{
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 700;
            }
            .single_information{
                margin-top: 36px;
                position: relative;
                border-bottom: 1px solid #8f8f8f;
                padding-bottom: 17px;
                h5{
                    font-size: 14px;
                    text-transform: capitalize;
                    font-weight: 500;
                    position: absolute;
                    left: 0;
                    top: 0;
                    @media #{$tab}{
                        position: relative;
                    }
                }
                ul{
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    padding-left: 26%;
                    @media #{$tab}{
                        padding-left: 0;
                    }
                    li{
                        color: $p_color;
                        font-weight: 300;
                        margin-bottom: 17px;
                        @media #{$tab}{
                            margin-bottom: 10px;
                        }
                        span{
                            color: $font_2;
                            padding-left: 5px;
                        }
                    }
                }
            }
            
        }
        .item_description{
            h4{
                margin-bottom: 29px;
            }
            p{
                font-size: 16px;
            }
        }
    }
    .single_information_img{
        img{
            margin-top: 35px;
        }
    }
    .review_part{
        h4 {
            font-size: 16px;
            text-transform: uppercase;
            margin-bottom: 26px;
        }

        .review_icon {
            display: flex;
            align-items: center;
            margin-top: 13px;
            margin-bottom: 22px;
            p {
                font-size: 13px;
                color: #222;
                display: inline-block;
            }
        }
        
        .review_icon_iner {
            margin-left: 28px;
            i{
                color: #ffb400;
                font-size: 12px;
            }
        }
        .form-control {
            border-radius: 0;
            height: 40px;
            padding: 10px 20px;
            margin-bottom: 20px;
            font-size: 13px;
        }
        .text_area {
            height: 100px;
        }
        ::placeholder{
            font-size: 13px;
            font-weight: 300;
        }
        .btn_1{
            padding: 17px 41px;
            margin-top: 10px;
            &:hover{
                border: 1px solid $base_color;
                background-color: transparent;
                color: $base_color;
            }
        }
    }
}