/******** related product *********/
.related_product{
    .section_tittle{
        margin-bottom: 35px;
        @media #{$tab}{
            margin-bottom: 15px;
        }
        @media #{$medium_device}{
            margin-bottom: 15px;
        }
        h4{
            text-align: left;
            font-size: 16px;
            text-transform: uppercase;
        }
    }
    .single_related_product{
        margin-bottom: 0;
        margin-top: 40px;
        @media #{$tab}{
            margin-bottom: 0;
            margin-top: 20px;
        }
        @media #{$medium_device}{
            margin-bottom: 0;
            margin-top: 30px;
        }
    }
    background-color: $white;
    .media_style .category_product_price span{
        text-decoration: none;
    }

    .media_style .category_product_price a{
        color: $p_color;
        &:hover{
            color: $base_color;
        }
    }
}
.single_product_details_weiget{
    @media #{$large_mobile}{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .single_product_weiget{
            flex: 48% 0 0;
        }
    }
    @media #{$tab_device}{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .single_product_weiget{
            flex: 45% 0 0;
        }
    }
}