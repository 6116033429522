/*********** send query css here *************/
.send_query{
    form{
        background-color: $white;
        padding: 75px 80px 80px;
        @media #{$tab}{
            padding: 20px 20px 30px;
        }
    }
    .form-group{
        margin-bottom: 23px;
        label{
            text-transform: uppercase;
            font-size: 11px;
            color: $font_2;
        }
        input{
            padding: 24px 20px;
            border: 1px solid $border_color_1;
            border-radius: 0;
            font-size: 13px;
            color: $p_color;
            text-transform: capitalize;
        }
        
        textarea{
            height: 160px;
            width: 100%;
            padding: 20px;
            font-size: 13px;
            border: 1px solid $border_color_1;
        }
        
    }
    
    .send_query_btn{
        margin: 0 auto;
        text-align: center;
        .btn_1{
            margin-top: 0;
        }
    }
}

/*********** send query css end *************/
