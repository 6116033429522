/************ id track css start **************/
.id_track{
   h5{
       font-size: 14px;
       text-transform: uppercase;
       color: $font_2;
       text-align: center;
       margin-bottom: 38px;
   } 
   label{
       font-size: 11px;
       text-transform: uppercase;
       color: $p_color;
       
   }
   input{
       border-radius: 0px;
       height: 50px;
       font-size: 14px;
       font-weight: 300;
       color: $p_color;
   }
   .input-group-text {
        font-size: 14px;
        border-radius: 0;
        border: 1px solid $base_color;
        background-color: $base_color;
        color: $white;
        padding: 13px 43px;
        cursor: pointer;
    }
}
/************ id track css end **************/